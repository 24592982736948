import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";

// components
import ProfileDetails from "elements/dashboard_elements/ProfileDetails";
import Cashier from "elements/dashboard_elements/Cashier";
import AccountActvity from "elements/dashboard_elements/AccountActvity";
import TransactionHistory from "elements/dashboard_elements/TransactionHistory";
import GamingHistory from "elements/dashboard_elements/GamingHistory";
import FreeSpins from "elements/dashboard_elements/FreeSpins";
import Notifications from "elements/dashboard_elements/Notifications";
import Messages from "elements/dashboard_elements/Messages";
import AccessControl from "elements/dashboard_elements/AccessControl";
import FavoriteGames from "elements/dashboard_elements/FavoriteGames";
import ReferAndEarn from "elements/dashboard_elements/ReferAndEarn";
import NftHolders from "elements/dashboard_elements/NftHolders";
import Verify from "elements/dashboard_elements/Verify";
import Withdraw from "elements/dashboard_elements/Withdraw";

export default function UserDashboard(props) {
  const params = useParams();
  const tab = params.tab;
  const [activeTab, setActiveTab] = useState("account-details");
  const [activeTabName, setActiveTabName] = useState("Account Details");
  const history = useHistory();

  const closeDetails = () => {
    history.push("/games/all");
  };

  const [travelingToRight, setTravelingToRight] = useState(false);

  useEffect(() => {
    setActiveTab(tab);

    setTravelingToRight(true);
    setTimeout(function () {
      setTravelingToRight(false);
    }, 500);

    // alert(tab)
  }, [tab]);

  return (
    <div className="container">
      <div
        className={
          "user_dashboard " + (travelingToRight ? "travelingToRight" : "")
        }
      >
        {activeTab === "account-details" && <ProfileDetails {...props} />}
        {activeTab === "cashier" && <Cashier {...props} />}
        {activeTab === "account-activity" && <AccountActvity {...props} />}
        {activeTab === "transaction-history" && (
          <TransactionHistory {...props} />
        )}
        {activeTab === "gaming-history" && <GamingHistory {...props} />}
        {activeTab === "free-spins" && <FreeSpins {...props} />}
        {activeTab === "notifications" && <Notifications {...props} />}
        {activeTab === "messages" && <Messages {...props} />}
        {activeTab === "responsible-gaming" && <AccessControl {...props} />}
        {activeTab === "my-games" && <FavoriteGames {...props} />}
        {activeTab === "refer-and-earn" && <ReferAndEarn {...props} />}
        {activeTab === "nft-holders" && <NftHolders {...props} />}
        {activeTab === "verify" && <Verify {...props} />}
        {activeTab === "withdraw" && <Withdraw {...props} />}
      </div>
    </div>
  );
}
