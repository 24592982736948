import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import api from "services/api";

const GameItem = ({ game, isAuthenticated }) => {
  const [visible, setVisible] = useState(true);
  const [processed, setProcessed] = useState(false);
  const loaderImage = require("assets/images/logos/game-loader.gif").default;
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(loaderImage);
  const defaultImage = require("assets/images/games/default.png").default;

  const hideNoImageFoundGames = () => {
    // setVisible(false);
    setGameSnapshotUrl(defaultImage);
  };

  const loadGameImage = () => {
    if (!processed) {
      setGameSnapshotUrl(game.game_snapshot_url);
      setProcessed(true);
    }
  };

  const fixImageRatio = (event) => {
    if (!processed) {
      setGameSnapshotUrl(game.game_snapshot_url);
      // var img = event.target;
      // console.log(img.width);
      // var width = img.width;
      // var height = width * 1;
      // img.height = height;
      // setTimeout(() => {
      //   //Sometimes the width is read wrong the first time
      //   //This is the fallback to fix it
      //   var width = img.width;
      //   var height = width * 1;
      //   img.height = height;
      // }, 1000);
      // setProcessed(true);
    }
  };

  useEffect(() => {
    // setProcessed(false);
    // setVisible(true);
    // setGameSnapshotUrl(loaderImage);

    setVisible(true);
    setGameSnapshotUrl(loaderImage);
    setProcessed(false);
  }, [game.id]);
  const [isFavorite, setIsFavorite] = useState(game.is_favorite_game);
  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", { gameId: game.id });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  return (
    <>
      <div className="single_game_v1">
        <div className="game_thumb">
          <Link
            hrefLang={Translator.getSelectedLanguage().code}
            to={"/play/" + game.slug}
          >
            <img
              src={gameSnapshotUrl}
              onError={hideNoImageFoundGames}
              onLoad={fixImageRatio}
              alt={game.game_name ?? ""}
            />
          </Link>
          <div class="game_overlay">
            <h3 class="game_name">{game.game_name}</h3>

            <div class="game_action">
              {/* {!isAuthenticated && (
                <Link
                  class="demo_game_play_action"
                  to={"/play/" + game.slug + "/demo"}
                >
                  {__("Demo")}
                </Link>
              )} */}
              {!isAuthenticated && (
                <Link
                  class="game_play_icon"
                  to={"/play/" + game.slug + "/demo"}
                >
                  <i class="fas fa-play"></i>
                </Link>
              )}
              {isAuthenticated && (
                <Link
                  class="game_play_icon"
                  to={"/play/" + game.slug + "/real"}
                >
                  <i class="fas fa-play"></i>
                </Link>
              )}

              {/* <svg
                data-v-9a692745=""
                class="svg-use"
                focusable="false"
                aria-hidden="true"
              >
                <use xlink="https://betfury.io/_nuxt/svg-sprite.b0996274.svg#icon-play"></use>
              </svg> */}
            </div>
            <h3 class="game_provider_name">{game.category}</h3>
          </div>
        </div>

        <div className="game_description">
          <Link className="game_title">
            {game?.game_name.length > 20
              ? game.game_name.substring(0, 20) + "..."
              : game.game_name}
            {/* {game.game_name ?? ""} */}
          </Link>
          {isAuthenticated && (
            <Link onClick={toggleFavoriteGame} className="favorite_icon">
              {isFavorite ? (
                <>
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2081_3472)">
                      <path
                        d="M14.6 0C12.5 0 10.8 1.3 10 2C9.2 1.3 7.4 0 5.4 0C2.4 0 0 2.4 0 5.4C0 7.2 1.7 8.9 1.8 9L10 17.2L18.2 9C18.3 8.9 20 7.2 20 5.4C20 2.4 17.6 0 14.6 0Z"
                        fill="#4698ED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2081_3472">
                        <rect width="20" height="17.2" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2081_1449)">
                      <path
                        d="M5.4 0C2.4 0 0 2.4 0 5.4C0 6.4 0.5 7.3 0.9 7.9C1.3 8.5 1.8 8.9 1.8 8.9L9.5 16.6L10 17.1L10.5 16.6L18.2 9C18.2 9 20 7.4 20 5.4C20 2.4 17.6 0 14.6 0C12.1 0 10.6 1.5 10 2.1C9.4 1.5 7.8 0 5.4 0ZM5.4 1.4C7.5 1.4 9.5 3.5 9.5 3.5L10 4.1L10.5 3.5C10.5 3.5 12.5 1.4 14.6 1.4C16.8 1.4 18.5 3.2 18.5 5.3C18.5 6.4 17.2 7.9 17.2 7.9L10 15.2L2.8 7.9C2.8 7.9 2.5 7.6 2.1 7.1C1.7 6.6 1.4 5.9 1.4 5.4C1.4 3.2 3.2 1.4 5.4 1.4Z"
                        fill="#4698ED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2081_1449">
                        <rect width="20" height="17.2" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </>
              )}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default GameItem;
