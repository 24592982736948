import React from "react";
import Translator, { __ } from "services/Translator";

export default function VipRakeback() {
  return (
    <div className="container">
      <div className="vip_rewards rakeback">
        <div className="heading text-left">
          {__("Casino Monte Olympus Cash-back and Rake-back")}
        </div>
        <div className="title text-left">
          {__(
            "Our Cash-back incentives is our delightful way of showing our appreciation. We want you to feel valued, and this is how we prove it. As you level up, enjoy boosted daily, weekly, and monthly cash-back rewards. Whether you're on a winning streak or facing a challenge, you'll always have a little something extra to smile about, courtesy of Casino Monte Olympus!"
          )}
        </div>
        {/*Asked to removed*/}
        <div className="single_rakeback d-none">
          <div className="row align-items-center">
            <div className="col-md-3 text-center">
              <img
                src={require("assets/images/v1/vip/daily-rakeback.svg").default}
              />
            </div>
            <div className="col-md-9">
              <div className="title small_heading text-left">
                {__("Daily Rakeback")}
              </div>
              <div className="title text-left padding_top_0">
                {__(
                  "Get up to 10% cashback from your previous days play by spinning the Casino Monte Olympus Cashback wheel. Every day you play and finish behind, you are guaranteed some funds back the next day!"
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="single_rakeback">
          <div className="row align-items-center">
            <div className="col-md-3 text-center">
              <img
                src={
                  require("assets/images/v1/vip/weekly-rakeback.svg").default
                }
              />
            </div>
            <div className="col-md-9">
              <div className="title small_heading text-left">
                {__("Weekly Rake-Back")}
              </div>
              <div className="title text-left padding_top_0">
                {__(
                  "The more you play, the higher you'll soar! Reach the Diamond VIP Level and enjoy an weekly 2.5% rake-back on all your bets (losses), exclusively tailored just for you as Diamond VIP on Mondays. This all on top of your cash-backs and weekly bonuses!"
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="single_rakeback">
          <div className="row align-items-center">
            <div className="col-md-3 text-center">
              <img
                src={require("assets/images/v1/vip/daily-rakeback.svg").default}
              />
            </div>
            <div className="col-md-9">
              <div className="title small_heading text-left">
                {__("Weekly Cash-back")}
              </div>
              <div className="title text-left padding_top_0">
                {__(
                  "Stay in the game and receive a guaranteed weekly cash-back based on your play, every week you're behind. It's our delightful way of saying 'thank you' for choosing Casino Monte Olympus – where fun and rewards never end!"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
