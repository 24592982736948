import React, { useState, useEffect } from "react";
import Banner from "../elements/Banner";
import { Link } from "react-router-dom";
import api from "../services/api";
import Pagination from "../elements/Pagination";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../elements/PageHeadline";

export default function Blogs() {
  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  //pagination data

  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1);

  const pageNum = (receiveVal) => {
    setPage(receiveVal);
  };

  //Get blog functionality

  const [articleData, setArticleData] = useState({});
  const [articleDataPagination, setArticleDataPagination] = useState({});
  const getArticleData = async (ev) => {
    var response = await api.post("/articles", {
      page: page,
      perPage: 6,
    });
    if (response.status === 200) {
      setActivePage(page);
      setArticleData(response.data.data);
      setArticleDataPagination(response.data.paginationData);
    }
    console.log("articleData", response.data);
  };
  useEffect(() => {
    getArticleData();
  }, [page]);

  const onPageChange = (page) => {
    setPage(page);
  };

  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const [popularArticleData, setPopularArticleData] = useState({});
  const getPopularArticleData = async (ev) => {
    var response = await api.post("/popular-articles", {
      limit: 3,
    });
    if (response.status === 200) {
      setPopularArticleData(response.data.data);
    }
    console.log("articleData", response.data);
  };

  const [recentArticleData, setRecentArticleData] = useState({});
  const getRecentArticleData = async (ev) => {
    var response = await api.post("/recent-articles", {
      limit: 3,
    });
    if (response.status === 200) {
      setRecentArticleData(response.data.data);
    }
    console.log("articleData", response.data);
  };

  useEffect(() => {
    getPopularArticleData();
    getRecentArticleData();
  }, []);

  return (
    <>
      <div className="blogs">
        <div className="container">
          <br />
          <br />
          <PageHeadline page_name={__("LATEST CRYPTO CASINO NEWS")} />
          <br />
          <br />
          <div className="row">
            <div className="col-md-8">
              <div className="blog_lists">
                {articleData.length > 0 &&
                  articleData.map((value, index) => (
                    <div className="single_blog">
                      <div className="blog_img">
                        <Link
                          hrefLang={Translator.getSelectedLanguage().code}
                          to={"/blog-details/" + value.slug}
                        >
                          {/* <img
                            src={
                              require("../assets/images/banners/promotions-banner.jpg")
                                .default
                            }
                          /> */}
                          <img
                            className="single_games_img"
                            src={value.blog_image_path}
                          />
                        </Link>
                      </div>

                      <Link
                        hrefLang={Translator.getSelectedLanguage().code}
                        className="blog_title"
                        to={"/blog-details/" + value.slug}
                      >
                        {value.blog_content.title}
                      </Link>

                      {/* <p className="paragraph no_margin text_elapsis white">
                        {renderHTML(value.blog_content.content)}
                      </p> */}
                      <Link
                        hrefLang={Translator.getSelectedLanguage().code}
                        to={"/blog-details/" + value.slug}
                        className="common_btn_v1 fit_width margin_auto"
                      >
                        {__("Read More")}
                      </Link>
                    </div>
                  ))}
              </div>
              <br/>
              <br/>

              <div className="pagination">
                <Pagination
                  data={articleDataPagination}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="others_blogs">
                <div>
                  <div className="others_blogs_headline">
                    <div className="common_btn_v1 fit_width radius_0">{__("Popular posts")}</div>
                  </div>
                  {popularArticleData.length > 0 &&
                    popularArticleData.map((value, index) => (
                      <div className="other_single_blog">
                        <div className="blog_img">
                          <Link
                            hrefLang={Translator.getSelectedLanguage().code}
                            to={"/blog-details/" + value.slug}
                          >
                            <img src={value.blog_image_path} />
                          </Link>
                        </div>
                        <Link
                          hrefLang={Translator.getSelectedLanguage().code}
                          to={"/blog-details/" + value.slug}
                          className="paragraph white weight_bold"
                        >
                          {value.blog_content.title}
                        </Link>
                      </div>
                    ))}
                </div>
                <br />
                <div>
                  <div className="others_blogs_headline">
                    <div className="common_btn_v1 fit_width radius_0">{__("Recent posts")}</div>
                  </div>
                  {recentArticleData.length > 0 &&
                    recentArticleData.map((value, index) => (
                      <div className="other_single_blog">
                        <div className="blog_img">
                          <Link
                            hrefLang={Translator.getSelectedLanguage().code}
                            to={"/blog-details/" + value.slug}
                          >
                            <img src={value.blog_image_path} />
                          </Link>
                        </div>
                        <Link
                          hrefLang={Translator.getSelectedLanguage().code}
                          to={"/blog-details/" + value.slug}
                          className="paragraph white weight_bold"
                        >
                          {value.blog_content.title}
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}
