import React, { useState, useEffect } from "react";

const Logo = (props) => {
  return (
    <>
      <svg
        width="98"
        height="57"
        viewBox="0 0 98 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M52.7118 23.0831C53.1944 20.5886 54.1596 18.0942 57.5376 18.0942C60.9156 18.0942 63.5884 20.5886 62.3634 25.5775C61.1384 30.5664 55.6073 33.0609 52.7118 38.087C49.8164 33.0981 44.2482 30.6036 43.0603 25.5775C41.8353 20.5886 44.5081 18.0942 47.8861 18.0942C51.2641 18.0569 52.2293 20.5514 52.7118 23.0831Z"
          fill="#CF2A2A"
        />
        <path
          d="M11.953 35.8156V35.071C13.6235 34.8848 13.7348 34.7731 13.6977 32.6882V24.1624C12.0644 27.9227 9.98561 32.7255 8.72348 35.7784H8.0553C6.90455 32.8371 5.04848 28.1461 3.48939 24.3486C3.19242 31.3852 3.19242 32.8744 3.19242 33.5073C3.22955 34.6987 3.675 34.9221 5.23409 35.071V35.8156H0V35.071C1.225 34.9593 1.78182 34.6987 1.9303 33.4701C2.04167 32.7255 2.07879 31.6458 2.59848 24.3113C2.67273 23.2689 2.48712 22.7477 0.631061 22.5615V21.8169H4.93712L9.2803 31.7947L13.772 21.8169H18.0038V22.5615C16.2591 22.7477 16.0364 22.9338 16.0735 24.572L16.222 32.6882C16.2591 34.7359 16.3333 34.8476 18.1152 35.071V35.8156H11.953Z"
          fill="#262262"
        />
        <path
          d="M31.9984 21.5566C35.8591 21.5566 39.0886 24.4234 39.0886 28.6677C39.0886 33.247 35.8962 36.151 31.8871 36.151C27.6553 36.151 24.6113 33.0237 24.6113 28.8911C24.6113 25.168 27.2469 21.5566 31.9984 21.5566ZM31.6272 22.4502C29.3628 22.4502 27.5439 24.5351 27.5439 28.4443C27.5439 32.2046 29.2515 35.2575 32.1841 35.2575C34.4113 35.2575 36.1189 33.2843 36.1189 29.3006C36.156 24.9446 34.2257 22.4502 31.6272 22.4502Z"
          fill="#262262"
        />
        <path
          d="M60.248 22.6733V21.9287H54.9768V22.6733C56.7215 22.785 57.1298 23.1946 57.2041 24.1253C57.2783 24.8699 57.3154 25.6518 57.3154 27.5505V31.9065L48.8518 21.9287H45.0283V22.6733C45.9935 22.785 46.476 22.8595 46.8473 23.3807C47.2556 23.9019 47.2927 24.3487 47.2927 25.0188V30.2311C47.2927 30.864 47.2927 31.348 47.2927 31.7948C47.6639 32.1299 47.998 32.5022 48.3692 32.8745C48.3321 32.2788 48.3321 31.497 48.3321 30.2684V25.205L55.7192 34.252C56.573 33.3585 57.501 32.5022 58.3177 31.6831V27.5505C58.3177 25.6518 58.392 24.8327 58.4291 24.0509C58.5033 23.0829 58.9488 22.8222 60.248 22.6733Z"
          fill="white"
        />
        <path
          d="M78.6971 25.4656C78.4744 24.5721 78.0661 23.7902 77.8433 23.4924C77.4721 23.0084 77.2865 22.8222 76.0615 22.8222H74.4282V33.0606C74.4282 34.8105 74.6138 34.9966 76.5812 35.1455V35.8902H69.788V35.1455C71.6812 34.9966 71.8668 34.8477 71.8668 33.0606V22.785H70.3449C69.157 22.785 68.8229 22.9712 68.4146 23.5296C68.1176 23.9391 67.8577 24.6465 67.5979 25.4656H66.8555C67.004 23.9764 67.1524 22.4127 67.1896 21.333H67.7835C68.1176 21.817 68.3403 21.8542 68.9714 21.8542H77.5093C78.1032 21.8542 78.363 21.7425 78.6971 21.333H79.2911C79.2911 22.2638 79.4024 24.0508 79.5138 25.3911L78.6971 25.4656Z"
          fill="#262262"
        />
        <path
          d="M97.2948 32.2416C97.1834 32.8 96.5895 35.1828 96.4039 35.8157H85.5273V35.1083C87.4948 34.9222 87.6433 34.8105 87.6433 32.9862V24.6838C87.6433 22.8222 87.3834 22.7105 85.8986 22.5616V21.817H92.7289C94.8819 21.817 95.6614 21.7798 95.9584 21.7798C95.9955 22.2266 96.0698 23.9392 96.1811 25.205L95.4016 25.3167C95.216 24.5721 94.8819 23.8647 94.5849 23.4552C94.2137 22.9339 93.8796 22.785 92.6917 22.785H91.0584C90.316 22.785 90.2417 22.8222 90.2417 23.5296V28.109H92.4319C93.7683 28.109 93.9539 27.7739 94.2508 26.2847H95.0304V30.9385H94.2508C93.9167 29.3748 93.7683 29.1142 92.3948 29.1142H90.2046V33.0234C90.2046 34.5126 90.3902 34.8477 91.6523 34.8849H93.3228C94.5478 34.8849 94.8448 34.736 95.3273 34.1403C95.8099 33.5819 96.2554 32.7628 96.5895 32.0554L97.2948 32.2416Z"
          fill="#262262"
        />
        <path
          d="M7.90665 42.4058C11.7673 42.4058 14.9968 45.2725 14.9968 49.5168C14.9968 54.0962 11.8044 57.0001 7.79529 57.0001C3.56347 57.0001 0.519531 53.8728 0.519531 49.7402C0.519531 46.0171 3.15514 42.4058 7.90665 42.4058ZM7.57256 43.2993C5.30817 43.2993 3.48923 45.3842 3.48923 49.2934C3.48923 53.0537 5.1968 56.1066 8.12938 56.1066C10.3567 56.1066 12.0642 54.1334 12.0642 50.1497C12.0642 45.7937 10.1339 43.2993 7.57256 43.2993Z"
          fill="#262262"
        />
        <path
          d="M28.1013 52.9418C27.9528 53.7608 27.3217 56.0319 27.1361 56.6276H16.7422V55.883C18.6354 55.7341 18.821 55.5851 18.821 53.7981V45.4957C18.821 43.6714 18.5982 43.5224 16.9649 43.3735V42.6289H23.3126V43.3735C21.6422 43.5224 21.4195 43.6714 21.4195 45.4957V53.9842C21.4195 55.2128 21.4937 55.6596 22.6816 55.6968C22.7187 55.6968 23.9066 55.7341 23.9808 55.7341C25.1687 55.7341 25.4657 55.5107 25.9854 54.9522C26.4308 54.4682 26.9505 53.5747 27.3217 52.7184L28.1013 52.9418Z"
          fill="#262262"
        />
        <path
          d="M40.1654 43.4482C38.7548 43.5971 38.3465 43.895 37.5298 45.1981C36.676 46.6128 35.9707 47.9159 34.9313 49.9636C34.7457 50.3731 34.6715 50.6337 34.6715 51.155V53.7983C34.6715 55.6226 34.8571 55.7343 36.676 55.9205V56.6651H30.1427V55.9205C31.9245 55.6971 32.073 55.6226 32.073 53.7983V51.2294C32.073 50.7826 31.9988 50.5593 31.776 50.1497C31.1079 48.8467 30.3283 47.4319 29.1033 45.2725C28.2866 43.8578 28.0268 43.5971 26.6904 43.4482V42.7036H32.6298V43.4482C31.145 43.6344 31.2192 43.9322 31.5904 44.6768C32.37 46.1661 33.298 47.9531 34.1518 49.554C34.8571 48.251 35.9336 46.0916 36.5276 44.863C36.973 43.9322 36.8245 43.6716 35.2283 43.4855V42.7408H40.1654V43.4482Z"
          fill="#262262"
        />
        <path
          d="M52.2665 56.6648V55.9202C53.937 55.7341 54.0483 55.6224 54.0112 53.5375V45.0117C52.3779 48.772 50.2991 53.5747 49.037 56.6276H48.3688C47.218 53.6864 45.362 48.9953 43.8029 45.1978C43.5059 52.1972 43.4688 53.6864 43.4688 54.3193C43.5059 55.5107 43.9514 55.7341 45.5105 55.883V56.6276H40.2764V55.883C41.5014 55.7713 42.0582 55.5107 42.2067 54.2821C42.318 53.5375 42.3552 52.4578 42.8749 45.1234C42.9491 44.0809 42.7635 43.5597 40.9074 43.3735V42.6289H45.2877L49.6309 52.6067L54.1226 42.6289H58.3544V43.3735C56.6097 43.5597 56.387 43.7458 56.4241 45.384L56.5726 53.5002C56.6097 55.5479 56.6839 55.6596 58.4658 55.883V56.6276H52.2665V56.6648Z"
          fill="#262262"
        />
        <path
          d="M66.0761 42.7032C67.7094 42.7032 68.9716 43.0011 69.8625 43.5968C70.8647 44.2669 71.4587 45.3094 71.4587 46.7614C71.4587 49.8887 68.8231 51.1918 66.5958 51.1918C66.3731 51.1918 66.1132 51.1546 65.9647 51.1546L64.6284 50.8195V53.8724C64.6284 55.6595 64.7769 55.7339 66.67 55.9201V56.6647H60.1738V55.9201C61.8443 55.7339 62.0299 55.6223 62.0299 53.8352V45.5328C62.0299 43.7085 61.8072 43.5595 60.1738 43.4106V42.666H66.0761V42.7032ZM64.6284 49.9632C64.8882 50.0749 65.3708 50.1866 65.9276 50.1866C67.0784 50.1866 68.7488 49.5537 68.7488 46.7614C68.7488 44.3786 67.3753 43.5223 65.8163 43.5223C65.2966 43.5223 64.9996 43.634 64.8511 43.7457C64.7026 43.8946 64.6284 44.118 64.6284 44.5275V49.9632Z"
          fill="#262262"
        />
        <path
          d="M87.829 43.4482C86.4555 43.5599 86.0471 43.9322 85.9729 44.7885C85.9358 45.3842 85.8987 46.1661 85.8987 47.8414V50.4848C85.8987 52.458 85.6017 54.2451 84.3767 55.5482C83.3744 56.5906 81.8896 57.0002 80.3676 57.0002C79.0312 57.0002 77.6206 56.7023 76.6555 55.9205C75.3934 54.9525 74.7994 53.5749 74.7994 51.155V45.7193C74.7994 43.7461 74.6138 43.6344 72.9434 43.4482V42.7036H79.2168V43.4482C77.5835 43.5971 77.3979 43.7461 77.3979 45.7193V51.3039C77.3979 53.9472 78.6971 55.5854 81.0729 55.5854C83.7828 55.5854 84.8593 53.6866 84.8593 50.3359V47.8414C84.8593 46.2033 84.785 45.3842 84.7479 44.7885C84.6737 43.8205 84.1168 43.5227 82.5949 43.4482V42.7036H87.829V43.4482Z"
          fill="#262262"
        />
        <path
          d="M96.7379 46.166C96.3295 44.7885 95.7356 43.2621 93.731 43.2621C92.3576 43.2621 91.6151 44.2673 91.6151 45.4214C91.6151 46.7245 92.4689 47.4319 94.2879 48.3626C96.6636 49.554 98 50.6337 98 52.7186C98 55.1386 95.9583 56.9629 92.9886 56.9629C91.6523 56.9629 90.4644 56.5906 89.4992 56.2928C89.3507 55.7715 89.0166 53.7983 88.8682 52.7186L89.6477 52.5325C90.0932 53.91 91.1697 56.1066 93.3227 56.1066C94.6962 56.1066 95.55 55.2131 95.55 53.8355C95.55 52.458 94.7333 51.7506 92.9144 50.7454C90.6871 49.554 89.2765 48.4743 89.2765 46.315C89.2765 44.1928 90.9841 42.4058 94.0651 42.4058C95.4386 42.4058 96.5894 42.7408 97.0348 42.8153C97.1091 43.5971 97.2576 44.6396 97.406 45.9799L96.7379 46.166Z"
          fill="#262262"
        />
        <path
          d="M12.9923 10.499C12.6953 11.5042 12.1756 12.9935 11.8415 13.6636C11.2476 13.7753 9.61423 14.1476 8.05514 14.1476C3.0809 14.1476 0.519531 10.8341 0.519531 7.1855C0.519531 2.94121 3.74908 0 8.42635 0C10.2082 0 11.6559 0.372306 12.3612 0.483997C12.4354 1.41476 12.5839 2.6806 12.7695 3.72306L12.1385 3.87198C11.7301 2.45722 11.2104 1.63815 10.171 1.19138C9.61423 0.930764 8.76044 0.781842 7.9809 0.781842C4.56574 0.781842 2.7468 3.31352 2.7468 6.77596C2.7468 10.7969 4.82559 13.403 8.20362 13.403C10.3195 13.403 11.3589 12.435 12.4354 10.3501L12.9923 10.499Z"
          fill="#262262"
        />
        <path
          d="M26.95 13.8498V13.2541C28.2864 13.1052 28.472 12.8818 28.175 12.0999C27.9152 11.2809 27.5068 10.2384 27.0614 8.93534H22.5697C22.2356 9.8661 21.9386 10.6852 21.6417 11.5415C21.1962 12.8818 21.4932 13.0679 23.1636 13.2541V13.8498H18.375V13.2541C19.6742 13.1052 20.0083 12.9562 20.7508 11.0575L25.0568 0.111692L25.725 0C27.0242 3.57413 28.472 7.52057 29.7712 11.0947C30.4394 12.919 30.625 13.0679 32.1098 13.2541V13.8498H26.95ZM24.8712 2.71783C24.203 4.5049 23.4977 6.40366 22.8667 8.0418H26.7644L24.8712 2.71783Z"
          fill="#262262"
        />
        <path
          d="M44.6195 3.4622C44.2483 2.08467 43.6915 0.744369 41.724 0.744369C40.0907 0.744369 39.5339 1.93575 39.5339 3.01543C39.5339 4.46743 40.4619 5.17481 42.1695 6.06834C44.6566 7.37141 45.7331 8.30217 45.7331 10.3126C45.7331 12.5092 43.8399 14.1846 41.1672 14.1846C39.9051 14.1846 38.8286 13.8123 37.9748 13.5145C37.8263 12.9188 37.5665 11.504 37.418 10.3871L38.049 10.2382C38.4574 11.6529 39.3854 13.44 41.5384 13.44C43.0233 13.44 43.9513 12.3975 43.9513 11.0572C43.9513 9.45632 43.0975 8.8234 41.2786 7.81818C39.0884 6.6268 37.8634 5.62157 37.8634 3.68558C37.8634 1.82406 39.3854 0.0742188 42.1324 0.0742188C43.2831 0.0742188 44.4339 0.372063 44.8793 0.483755C44.9536 1.22837 45.0278 2.08467 45.2134 3.42497L44.6195 3.4622Z"
          fill="#262262"
        />
        <path
          d="M51.71 13.8498V13.2541C53.4175 13.0679 53.566 12.9935 53.566 11.2064V2.94122C53.566 1.15415 53.4175 1.07969 51.71 0.893541V0.297852H57.2781V0.893541C55.5706 1.07969 55.385 1.15415 55.385 2.94122V11.2064C55.385 12.9935 55.5334 13.0679 57.2781 13.2541V13.8498H51.71Z"
          fill="#262262"
        />
        <path
          d="M77.9178 0.893541C76.5814 1.04246 76.136 1.26585 76.0989 2.19661C76.0617 2.86676 76.0246 3.72306 76.0246 5.69628V14.0732H75.2451L66.336 3.16461V8.37688C66.336 10.2384 66.4102 11.2064 66.4473 11.8021C66.4845 12.8818 67.0042 13.1796 68.489 13.2913V13.887H63.4405V13.2913C64.7398 13.1796 65.2223 12.8818 65.2966 11.8393C65.3708 11.1692 65.4079 10.2384 65.4079 8.33965V3.01568C65.4079 2.41999 65.3708 2.08492 64.9996 1.63815C64.5913 1.11692 64.1829 1.00523 63.1436 0.893541V0.297852H66.2617L75.0966 10.7969V5.65905C75.0966 3.72306 75.0595 2.82953 74.9852 2.19661C74.911 1.26585 74.4284 0.968002 72.7951 0.85631V0.297852H77.9178V0.893541Z"
          fill="#262262"
        />
        <path
          d="M90.7239 0C94.2876 0 97.4429 2.71783 97.4429 6.88766C97.4429 11.3553 94.3989 14.1476 90.5754 14.1476C86.5664 14.1476 83.6709 11.0947 83.6709 7.1855C83.6709 3.46244 86.3436 0 90.7239 0ZM90.3156 0.781842C87.977 0.781842 85.8611 2.75506 85.8611 6.62704C85.8611 10.499 87.977 13.3658 90.9467 13.3658C93.4709 13.3658 95.3641 11.5042 95.3641 7.48334C95.327 3.09014 93.0254 0.781842 90.3156 0.781842Z"
          fill="#262262"
        />
      </svg>
    </>
  );
};

export default Logo;
