import ls from "local-storage";
import Translator, { __ } from "services/Translator";
//Auth module
import PrivateRoute from "./PrivateRoute";
import OpenRoute from "./OpenRoute";
import ChatRoute from "routes/ChatRoute";

// import external modules
import React, { Component, lazy } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import Landing from "pages/Landing";
import UserDashboard from "pages/UserDashboard";

import Promotions from "pages/Promotions";
import TermsAndconditions from "pages/TermsAndconditions";
import PrivacyPolicy from "pages/PrivacyPolicy";
import BonusTerms from "pages/BonusTerms";
import AMLPolicy from "pages/AMLPolicy";
import ResponsibleGaming from "pages/ResponsibleGaming";
import About from "pages/About";
import ProvablyFair from "pages/ProvablyFair";

import Vip from "pages/Vip";
import Games from "pages/Games";
import Sports from "pages/Sports";
import GamePlay from "pages/GamePlay";
import FAQ from "pages/FAQ";
import Races from "pages/Races";

import Blogs from "../pages/Blogs";
import BlogDetails from "../pages/BlogDetails";

// auth pages
import RecoverPassword from "pages/auth/RecoverPassword";
import ResetPassword from "pages/auth/ResetPassword";

import Chat from "elements/Chat";
import NoMatch from "pages/NoMatch";

//Stores
import Stores from "../pages/Stores";

//Tickets
import Tickets from "pages/Tickets";
import TicketDetails from "pages/TicketDetails";

class OpenRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <OpenRoute exact path="/" component={Landing} />
          <OpenRoute
            exact
            path="/account-activated"
            activated={true}
            component={Landing}
          />
          <OpenRoute exact path="/refer/:referral_code" component={Landing} />
          <OpenRoute exact path="/promotions" component={Promotions} />
          <OpenRoute
            exact
            path="/terms-and-conditions"
            component={TermsAndconditions}
          />
          <OpenRoute exact path="/privacy-policy" component={PrivacyPolicy} />
          <OpenRoute exact path="/provably-fair" component={ProvablyFair} />
          <OpenRoute exact path="/bonus-terms" component={BonusTerms} />
          <OpenRoute exact path="/aml-policy" component={AMLPolicy} />
          <OpenRoute
            exact
            path="/responsible-gaming"
            component={ResponsibleGaming}
          />
          <OpenRoute exact path="/about" component={About} />
          <OpenRoute exact path="/sports" component={Sports} />
          <OpenRoute exact path="/vip" component={Vip} />
          <OpenRoute exact path="/faq" component={FAQ} />
          <OpenRoute exact path="/games/:category" component={Games} />
          <OpenRoute exact path="/play/:slug/:mode?" component={GamePlay} />
          {/* auth pages */}
          <OpenRoute
            exact
            path="/recover-password"
            component={RecoverPassword}
          />
          <OpenRoute
            exact
            path="/reset-password/:userId/:code"
            component={ResetPassword}
          />

          <OpenRoute exact path="/blogs" component={Blogs} />
          <OpenRoute exact path="/races" component={Races} />
          <OpenRoute exact path="/blog-details/:slug" component={BlogDetails} />
          {/* <Redirect path="*" to="/" /> */}
          <OpenRoute path="*" component={NoMatch} status={404} />
        </Switch>
      </BrowserRouter>
    );
  }
}

class PrivateRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <PrivateRoute exact path="/" component={Landing} />

          <PrivateRoute exact path="/games/:category" component={Games} />
          <PrivateRoute exact path="/play/:slug/:mode?" component={GamePlay} />
          <PrivateRoute
            exact
            path="/dashboard/:tab?"
            component={UserDashboard}
          />
          <PrivateRoute exact path="/sports" component={Sports} />
          <PrivateRoute exact path="/promotions" component={Promotions} />
          <PrivateRoute exact path="/faq" component={FAQ} />
          <PrivateRoute
            exact
            path="/terms-and-conditions"
            component={TermsAndconditions}
          />
          <PrivateRoute
            exact
            path="/privacy-policy"
            component={PrivacyPolicy}
          />
          <PrivateRoute exact path="/provably-fair" component={ProvablyFair} />
          <PrivateRoute exact path="/bonus-terms" component={BonusTerms} />
          <PrivateRoute exact path="/aml-policy" component={AMLPolicy} />
          <PrivateRoute
            exact
            path="/responsible-gaming"
            component={ResponsibleGaming}
          />
          <PrivateRoute exact path="/about" component={About} />
          <PrivateRoute exact path="/vip" component={Vip} />
          <PrivateRoute exact path="/promotions" component={Promotions} />

          <PrivateRoute exact path="/blogs" component={Blogs} />
          <PrivateRoute exact path="/races" component={Races} />
          <PrivateRoute
            exact
            path="/blog-details/:slug"
            component={BlogDetails}
          />
          <ChatRoute exact path="/chat" component={Chat} />

          <PrivateRoute exact path="/tickets" component={Tickets} />
          <PrivateRoute
            exact
            path="/ticket-details/:id"
            component={TicketDetails}
          />

          <PrivateRoute exact path="/store" component={Stores}></PrivateRoute>

          <PrivateRoute path="*" component={NoMatch} status={404} />
          {/* <Redirect path="*" to="/" /> */}
        </Switch>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };
