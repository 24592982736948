import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import api from "services/api";

export default function Winner(props) {
  const userBalance = props.userBalance;
  const socket = props.socket;
  const [winnerTab, setWinnerTab] = useState("Recent Winners");
  const [recentWinners, setRecentWinners] = useState([]);
  const getRecentWinners = async () => {
    var response = await api.get("/games/recent-winners", { limit: 9 });
    if (response.status === 200) {
      setRecentWinners(response.data.data);
      // console.log('response.data', response.data);
    }
  };

  const [topWinners, setTopWinners] = useState([]);
  const getTopWinners = async () => {
    var response = await api.get("/games/top-winners", { limit: 9 });
    if (response.status === 200) {
      setTopWinners(response.data.data);
    }
  };
  const checkIframe = () => {
    if (window.parent.parent.location.href != window.location.href) {
      window.parent.location.href = window.location.href;
    } else {
    }
  };

  const getRecentWinsFromSocket = (data) => {
    var winners = JSON.parse(JSON.stringify(recentWinners));
    winners.unshift(JSON.parse(data));
    if (winners.length > 10) {
      winners.splice(10, winners.length - 10);
    }
    setRecentWinners(winners);
  };

  useEffect(async () => {
    getTopWinners();
    getRecentWinners();
    checkIframe();

    var timeout;

    timeout = setInterval(() => {
      getTopWinners();
      getRecentWinners();
    }, 15000);

    return () => {
      clearInterval(timeout);
    };
  }, []);

  // const styles = {
  //   flipInX: {
  //     animation: "x 2s",
  //     animationName: Radium.keyframes(flipInX, "flipInX"),
  //     animationIterationCount: 1,
  //   },
  // };
  return (
    <div className="winner_section_v1">
      <div className="container">
        <Tabs
          defaultActiveKey="Recent winners"
          id="fill-tab-example"
          className="winner_tabs"
        >
          <Tab eventKey="Recent winners" title="Recent winners">
            <div className="row">
              {recentWinners.length > 0 &&
                recentWinners.map((value, index) => (
                  <div className="col-12 col-md-6 col-lg-4">
                    <Link
                      className="item"
                      to={"/play/" + value.game?.slug}
                      style={{ color: "white" }}
                    >
                      <div className="image">
                        {/* <img
                          src={
                            require("assets/images/v1/games/game-1.png").default
                          }
                        /> */}
                        <img src={value.game?.game_snapshot_url} />
                      </div>
                      <div className="game_text">
                        <div className="headline">
                          {/* {__("All Mine Gold")} */}

                          {value.game?.game_name}
                        </div>
                        <div className="game_dsc">
                          {__("Bet: ")}{" "}
                          <span className="dark">${value.bet_amount}</span>
                          <br />
                          {__("Win: ")}{" "}
                          <span className="dark">${value.win_amount}</span>
                        </div>
                        <div className="game_dsc">{__("Gamer")}</div>
                      </div>
                    </Link>
                  </div>
                ))}
              <div className="col-12 col-md-6 col-lg-4">
                <Link className="item">
                  <div className="image">
                    <img
                      src={require("assets/images/v1/games/game-2.png").default}
                    />
                  </div>
                  <div className="game_text">
                    <div className="headline">{__("All Mine Gold")}</div>
                    <div className="game_dsc">
                      {__("Best: ")} <span className="dark">$0.10</span>
                      <br />
                      {__("Win: ")} <span className="dark">$0.50</span>
                    </div>
                    <div className="game_dsc">{__("Gamer")}</div>
                  </div>
                </Link>
              </div>
              <div className="col-12  col-md-6 col-lg-4">
                <Link className="item">
                  <div className="image">
                    <img
                      src={require("assets/images/v1/games/game-3.png").default}
                    />
                  </div>
                  <div className="game_text">
                    <div className="headline">{__("All Mine Gold")}</div>
                    <div className="game_dsc">
                      {__("Best: ")} <span className="dark">$0.10</span>
                      <br />
                      {__("Win: ")} <span className="dark">$0.50</span>
                    </div>
                    <div className="game_dsc">{__("Gamer")}</div>
                  </div>
                </Link>
              </div>
              <div className="col-12  col-md-6 col-lg-4">
                <Link className="item">
                  <div className="image">
                    <img
                      src={require("assets/images/v1/games/game-4.png").default}
                    />
                  </div>
                  <div className="game_text">
                    <div className="headline">{__("All Mine Gold")}</div>
                    <div className="game_dsc">
                      {__("Best: ")} <span className="dark">$0.10</span>
                      <br />
                      {__("Win: ")} <span className="dark">$0.50</span>
                    </div>
                    <div className="game_dsc">{__("Gamer")}</div>
                  </div>
                </Link>
              </div>
              <div className="col-12  col-md-6 col-lg-4">
                <Link className="item">
                  <div className="image">
                    <img
                      src={require("assets/images/v1/games/game-5.png").default}
                    />
                  </div>
                  <div className="game_text">
                    <div className="headline">{__("All Mine Gold")}</div>
                    <div className="game_dsc">
                      {__("Best: ")} <span className="dark">$0.10</span>
                      <br />
                      {__("Win: ")} <span className="dark">$0.50</span>
                    </div>
                    <div className="game_dsc">{__("Gamer")}</div>
                  </div>
                </Link>
              </div>
              <div className="col-12  col-md-6 col-lg-4">
                <Link className="item">
                  <div className="image">
                    <img
                      src={require("assets/images/v1/games/game-6.png").default}
                    />
                  </div>
                  <div className="game_text">
                    <div className="headline">{__("All Mine Gold")}</div>
                    <div className="game_dsc">
                      {__("Best: ")} <span className="dark">$0.10</span>
                      <br />
                      {__("Win: ")} <span className="dark">$0.50</span>
                    </div>
                    <div className="game_dsc">{__("Gamer")}</div>
                  </div>
                </Link>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Big winners" title="Big winners">
            <div className="row">
              {topWinners.length > 0 &&
                topWinners.map((value, index) => (
                  <div className="col-12  col-md-6 col-lg-4">
                    <Link
                      className="item"
                      to={"/play/" + value.game?.slug}
                      style={{ color: "white" }}
                    >
                      <div className="image">
                        {/* <img
                          src={
                            require("assets/images/v1/games/game-1.png").default
                          }
                        /> */}
                        <img src={value.game?.game_snapshot_url} />
                      </div>
                      <div className="game_text">
                        <div className="headline">{value.game?.game_name}</div>
                        <div className="game_dsc">
                          {__("Bet: ")}{" "}
                          <span className="dark">${value.bet_amount}</span>
                          <br />
                          {__("Win: ")}{" "}
                          <span className="dark">${value.win_amount}</span>
                        </div>
                        <div className="game_dsc">{__("Gamer")}</div>
                      </div>
                    </Link>
                  </div>
                ))}
              <div className="col-12  col-md-6 col-lg-4">
                <Link className="item">
                  <div className="image">
                    <img
                      src={require("assets/images/v1/games/game-2.png").default}
                    />
                  </div>
                  <div className="game_text">
                    <div className="headline">{__("All Mine Gold")}</div>
                    <div className="game_dsc">
                      {__("Best: ")} <span className="dark">$0.10</span>
                      <br />
                      {__("Win: ")} <span className="dark">$0.50</span>
                    </div>
                    <div className="game_dsc">{__("Gamer")}</div>
                  </div>
                </Link>
              </div>
              <div className="col-12  col-md-6 col-lg-4">
                <Link className="item">
                  <div className="image">
                    <img
                      src={require("assets/images/v1/games/game-3.png").default}
                    />
                  </div>
                  <div className="game_text">
                    <div className="headline">{__("All Mine Gold")}</div>
                    <div className="game_dsc">
                      {__("Best: ")} <span className="dark">$0.10</span>
                      <br />
                      {__("Win: ")} <span className="dark">$0.50</span>
                    </div>
                    <div className="game_dsc">{__("Gamer")}</div>
                  </div>
                </Link>
              </div>
              <div className="col-12  col-md-6 col-lg-4">
                <Link className="item">
                  <div className="image">
                    <img
                      src={require("assets/images/v1/games/game-4.png").default}
                    />
                  </div>
                  <div className="game_text">
                    <div className="headline">{__("All Mine Gold")}</div>
                    <div className="game_dsc">
                      {__("Best: ")} <span className="dark">$0.10</span>
                      <br />
                      {__("Win: ")} <span className="dark">$0.50</span>
                    </div>
                    <div className="game_dsc">{__("Gamer")}</div>
                  </div>
                </Link>
              </div>
              <div className="col-12  col-md-6 col-lg-4">
                <Link className="item">
                  <div className="image">
                    <img
                      src={require("assets/images/v1/games/game-5.png").default}
                    />
                  </div>
                  <div className="game_text">
                    <div className="headline">{__("All Mine Gold")}</div>
                    <div className="game_dsc">
                      {__("Best: ")} <span className="dark">$0.10</span>
                      <br />
                      {__("Win: ")} <span className="dark">$0.50</span>
                    </div>
                    <div className="game_dsc">{__("Gamer")}</div>
                  </div>
                </Link>
              </div>
              <div className="col-12  col-md-6 col-lg-4">
                <Link className="item">
                  <div className="image">
                    <img
                      src={require("assets/images/v1/games/game-6.png").default}
                    />
                  </div>
                  <div className="game_text">
                    <div className="headline">{__("All Mine Gold")}</div>
                    <div className="game_dsc">
                      {__("Best: ")} <span className="dark">$0.10</span>
                      <br />
                      {__("Win: ")} <span className="dark">$0.50</span>
                    </div>
                    <div className="game_dsc">{__("Gamer")}</div>
                  </div>
                </Link>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
