import React, { useState, useEffect, useRef } from "react";
import api from "services/api";
import { Collapse } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";


const SearchBox = (props) => {
  const history = useHistory();
  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchTextBox = useRef(null);

  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      console.log("getSearchSuggestions", response.data);
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);
  const doSearchByGameName = (text) => {
    setSearchStr("");
    props.setSearchStr(text);
    setSearchSuggestions([]);
    toggleExpandedSearchBar(true);
    history.push("/games/search");
  };
  return (
    <>
      {!!searchSuggestions.length && <div onClick={() => setSearchSuggestions([])} className="invisible_overlay"></div>}
      <div className="searchbar_searchbox">
        <input
          className="form-control"
          id="searchFocusInput"
          placeholder={__("Search...")}
          autoComplete="off"
          value={searchStr}
          onChange={(ev) => setSearchStr(ev.target.value)}
          type="text"
          ref={searchTextBox}
        />
        <span className="search-icon position-absolute">
          <i className="fas fa-search"></i>
        </span>

        <Collapse style={{ zIndex: 3050 }} in={!!searchSuggestions.length}>
          <div className="search_container">
            <div className="caretUp">
              <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.1018 16.9814C5.02785 16.9814 4.45387 15.7165 5.16108 14.9083L10.6829 8.59762C11.3801 7.80079 12.6197 7.80079 13.3169 8.59762L18.8388 14.9083C19.5459 15.7165 18.972 16.9814 17.898 16.9814H6.1018Z"
                  fill="#212121"
                />
              </svg>
            </div>

            <ul className="search_suggestions">
              {searchSuggestions.map((item, index) => (
                <li onClick={() => doSearchByGameName(item.game_name)} key={index}>
                  <img
                    alt={item.game_name}
                    src={item.game_snapshot_url}
                    onError={(event) => (event.target.parentElement.style.display = "none")}
                  />
                  {item.game_name}
                </li>
              ))}
            </ul>
          </div>
        </Collapse>
      </div>
    </>
  );
};
export default SearchBox;
