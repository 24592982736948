import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";

export default function BottomBanner(props) {
  return (
    <div className="bottom_banner_v1">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="single_item">
              <div className="description">
                <div className="heading">{__("Olympus VIP Majesty")}</div>
                <div className="desc">
                  {__(
                    "Casino Monte Olympus – where VIP players revel in exclusive perks, divine rewards, and unparalleled service fit for the Gods themselves!"
                  )}
                </div>
                <div className="links">
                  {!props.isAuthenticated && (
                    <Link
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                      className="common_btn_v1 fit_width"
                    >
                      {__("Register")}
                    </Link>
                  )}
                </div>
              </div>
              <div className="icon">
                <img src={require("assets/images/v1/banner/diamond.png").default} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="single_item">
              <div className="description">
                <div className="heading">{__("Reel Races Rumble")}</div>
                <div className="desc">
                  {__(
                    "Gear up for high-stakes fun in our Slot-athlon Showdown – weekly and monthly slot races for a shot at legendary prizes!"
                  )}
                </div>

                <div className="links">
                  {!props.isAuthenticated && (
                    <Link
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                      className="common_btn_v1 fit_width red text-white"
                    >
                      {__("Register")}
                    </Link>
                  )}
                </div>
              </div>
              <div className="icon">
                <img src={require("assets/images/v1/banner/giftbox.png").default} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
