import React, { useState, useEffect, useRef } from "react";
import api from "services/api";
import { Collapse } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import { Modal, Dropdown } from "react-bootstrap";
import CheckBox from "elements/CheckBox";

const ProviderFilterDropdown = (props) => {
  const history = useHistory();

  const [providersList, setProvidersList] = useState(false);

  const providers = props.providers;
  const selectedProviders = props.selectedProviders;
  const setSelectedProviders = props.setSelectedProviders;

  const toggleSelectedProvider = (providerId) => {
    var providersClone = JSON.parse(JSON.stringify(selectedProviders));
    if (providersClone.indexOf(providerId) === -1) {
      providersClone.push(providerId);
    } else {
      providersClone.splice(providersClone.indexOf(providerId), 1);
    }

    console.log("providersClone", providersClone);

    setSelectedProviders(providersClone);
    history.push("/games/all");
  };

  return (
    <>
      <Dropdown className="gameProviderDropdown">
        <Dropdown.Toggle
          className="providerBtn"
          variant="success"
          id="dropdown-basic"
        >
          {__("Providers")}{" "}
          {selectedProviders.length > 0 ? (
            <span className="countNum">{selectedProviders.length}</span>
          ) : (
            ""
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className="providerLists">
          {providers.map((item, index) => (
            <>
              <div>
                <label for={item.provider_id}>
                  {selectedProviders.indexOf(item.provider_id) !== -1 ? (
                    <input
                      defaultChecked={true}
                      id={item.provider_id}
                      type="checkbox"
                      onChange={() => {
                        toggleSelectedProvider(item.provider_id);
                      }}
                    />
                  ) : (
                    <input
                      defaultChecked={false}
                      id={item.provider_id}
                      type="checkbox"
                      onChange={() => {
                        toggleSelectedProvider(item.provider_id);
                      }}
                    />
                  )}

                  {/* <input
                    defaultChecked={
                      selectedProviders.indexOf(item.provider_id) !== -1
                        ? true
                        : false
                    }
                    id={item.provider_id}
                    type="checkbox"
                    onChange={() => {
                      toggleSelectedProvider(item.provider_id);
                    }}
                  /> */}

                  <div className="checkboxDesign"></div>
                  <div className="checkboxText">{item.name}</div>
                  <div className="gameCount">{item.gameCount}</div>
                </label>
              </div>
            </>
          ))}
          <Link
            onClick={() => {
              setSelectedProviders([]);
            }}
            className="clearListBtn"
          >
            {__("Clear Lists")}
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
export default ProviderFilterDropdown;
