import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HomeSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    // initialSlide: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1.3,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="home_slider_v1">
      <div className="container">
        <div className="home_slider_heading">
          <div className="headline">{__("Hot games")}</div>
          <div className="links">
            <Link>
              {__("See all")}{" "}
              <span className="icon">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="#1F2933"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
            </Link>
          </div>
        </div>
        <div className="home_slider_carousel">
          <Slider {...settings}>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-1.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__("All Mine Gold All Mine Diamonds All Mine Diamonds")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="41"
                  height="36"
                  viewBox="0 0 41 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_1847_3477)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M37 17.5941V4.63366C36.9115 2.9703 35.4948 2 33.5469 2H4C5.68229 2.06931 7.30208 3.31683 7.30208 4.56436V18.3564C7.83333 24.8713 13.7396 30 22.151 30C30.9167 30 37 24.4554 37 17.5941Z"
                      fill="url(#paint0_linear_1847_3477)"
                    />
                  </g>
                  <path
                    d="M18.48 12.02V19H17.08V16.03H14.09V19H12.69V12.02H14.09V14.89H17.08V12.02H18.48ZM23.098 19.07C22.4446 19.07 21.8446 18.9167 21.298 18.61C20.7513 18.3033 20.318 17.88 19.998 17.34C19.678 16.7933 19.518 16.1767 19.518 15.49C19.518 14.81 19.678 14.2 19.998 13.66C20.318 13.1133 20.7513 12.6867 21.298 12.38C21.8446 12.0733 22.4446 11.92 23.098 11.92C23.758 11.92 24.358 12.0733 24.898 12.38C25.4446 12.6867 25.8746 13.1133 26.188 13.66C26.508 14.2 26.668 14.81 26.668 15.49C26.668 16.1767 26.508 16.7933 26.188 17.34C25.8746 17.88 25.4446 18.3033 24.898 18.61C24.3513 18.9167 23.7513 19.07 23.098 19.07ZM23.098 17.82C23.518 17.82 23.888 17.7267 24.208 17.54C24.528 17.3467 24.778 17.0733 24.958 16.72C25.138 16.3667 25.228 15.9567 25.228 15.49C25.228 15.0233 25.138 14.6167 24.958 14.27C24.778 13.9167 24.528 13.6467 24.208 13.46C23.888 13.2733 23.518 13.18 23.098 13.18C22.678 13.18 22.3046 13.2733 21.978 13.46C21.658 13.6467 21.408 13.9167 21.228 14.27C21.048 14.6167 20.958 15.0233 20.958 15.49C20.958 15.9567 21.048 16.3667 21.228 16.72C21.408 17.0733 21.658 17.3467 21.978 17.54C22.3046 17.7267 22.678 17.82 23.098 17.82ZM32.4595 12.02V13.15H30.5995V19H29.1995V13.15H27.3395V12.02H32.4595Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1847_3477"
                      x="0"
                      y="0"
                      width="41"
                      height="36"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1847_3477"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1847_3477"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_1847_3477"
                      x1="21.9981"
                      y1="2.61247"
                      x2="21.9981"
                      y2="27.9475"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.161458" stop-color="#912D28" />
                      <stop offset="0.5" stop-color="#B55A5A" />
                      <stop offset="0.942708" stop-color="#922F29" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-2.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__("All Mine Gold All Mine Diamonds All Mine Diamonds")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}{" "}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="41"
                  height="36"
                  viewBox="0 0 41 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_1847_3477)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M37 17.5941V4.63366C36.9115 2.9703 35.4948 2 33.5469 2H4C5.68229 2.06931 7.30208 3.31683 7.30208 4.56436V18.3564C7.83333 24.8713 13.7396 30 22.151 30C30.9167 30 37 24.4554 37 17.5941Z"
                      fill="url(#paint0_linear_1847_3477)"
                    />
                  </g>
                  <path
                    d="M18.48 12.02V19H17.08V16.03H14.09V19H12.69V12.02H14.09V14.89H17.08V12.02H18.48ZM23.098 19.07C22.4446 19.07 21.8446 18.9167 21.298 18.61C20.7513 18.3033 20.318 17.88 19.998 17.34C19.678 16.7933 19.518 16.1767 19.518 15.49C19.518 14.81 19.678 14.2 19.998 13.66C20.318 13.1133 20.7513 12.6867 21.298 12.38C21.8446 12.0733 22.4446 11.92 23.098 11.92C23.758 11.92 24.358 12.0733 24.898 12.38C25.4446 12.6867 25.8746 13.1133 26.188 13.66C26.508 14.2 26.668 14.81 26.668 15.49C26.668 16.1767 26.508 16.7933 26.188 17.34C25.8746 17.88 25.4446 18.3033 24.898 18.61C24.3513 18.9167 23.7513 19.07 23.098 19.07ZM23.098 17.82C23.518 17.82 23.888 17.7267 24.208 17.54C24.528 17.3467 24.778 17.0733 24.958 16.72C25.138 16.3667 25.228 15.9567 25.228 15.49C25.228 15.0233 25.138 14.6167 24.958 14.27C24.778 13.9167 24.528 13.6467 24.208 13.46C23.888 13.2733 23.518 13.18 23.098 13.18C22.678 13.18 22.3046 13.2733 21.978 13.46C21.658 13.6467 21.408 13.9167 21.228 14.27C21.048 14.6167 20.958 15.0233 20.958 15.49C20.958 15.9567 21.048 16.3667 21.228 16.72C21.408 17.0733 21.658 17.3467 21.978 17.54C22.3046 17.7267 22.678 17.82 23.098 17.82ZM32.4595 12.02V13.15H30.5995V19H29.1995V13.15H27.3395V12.02H32.4595Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1847_3477"
                      x="0"
                      y="0"
                      width="41"
                      height="36"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1847_3477"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1847_3477"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_1847_3477"
                      x1="21.9981"
                      y1="2.61247"
                      x2="21.9981"
                      y2="27.9475"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.161458" stop-color="#912D28" />
                      <stop offset="0.5" stop-color="#B55A5A" />
                      <stop offset="0.942708" stop-color="#922F29" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-3.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__("All Mine Gold All Mine Diamonds All Mine Diamonds")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}{" "}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="41"
                  height="36"
                  viewBox="0 0 41 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_1847_3477)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M37 17.5941V4.63366C36.9115 2.9703 35.4948 2 33.5469 2H4C5.68229 2.06931 7.30208 3.31683 7.30208 4.56436V18.3564C7.83333 24.8713 13.7396 30 22.151 30C30.9167 30 37 24.4554 37 17.5941Z"
                      fill="url(#paint0_linear_1847_3477)"
                    />
                  </g>
                  <path
                    d="M18.48 12.02V19H17.08V16.03H14.09V19H12.69V12.02H14.09V14.89H17.08V12.02H18.48ZM23.098 19.07C22.4446 19.07 21.8446 18.9167 21.298 18.61C20.7513 18.3033 20.318 17.88 19.998 17.34C19.678 16.7933 19.518 16.1767 19.518 15.49C19.518 14.81 19.678 14.2 19.998 13.66C20.318 13.1133 20.7513 12.6867 21.298 12.38C21.8446 12.0733 22.4446 11.92 23.098 11.92C23.758 11.92 24.358 12.0733 24.898 12.38C25.4446 12.6867 25.8746 13.1133 26.188 13.66C26.508 14.2 26.668 14.81 26.668 15.49C26.668 16.1767 26.508 16.7933 26.188 17.34C25.8746 17.88 25.4446 18.3033 24.898 18.61C24.3513 18.9167 23.7513 19.07 23.098 19.07ZM23.098 17.82C23.518 17.82 23.888 17.7267 24.208 17.54C24.528 17.3467 24.778 17.0733 24.958 16.72C25.138 16.3667 25.228 15.9567 25.228 15.49C25.228 15.0233 25.138 14.6167 24.958 14.27C24.778 13.9167 24.528 13.6467 24.208 13.46C23.888 13.2733 23.518 13.18 23.098 13.18C22.678 13.18 22.3046 13.2733 21.978 13.46C21.658 13.6467 21.408 13.9167 21.228 14.27C21.048 14.6167 20.958 15.0233 20.958 15.49C20.958 15.9567 21.048 16.3667 21.228 16.72C21.408 17.0733 21.658 17.3467 21.978 17.54C22.3046 17.7267 22.678 17.82 23.098 17.82ZM32.4595 12.02V13.15H30.5995V19H29.1995V13.15H27.3395V12.02H32.4595Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1847_3477"
                      x="0"
                      y="0"
                      width="41"
                      height="36"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1847_3477"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1847_3477"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_1847_3477"
                      x1="21.9981"
                      y1="2.61247"
                      x2="21.9981"
                      y2="27.9475"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.161458" stop-color="#912D28" />
                      <stop offset="0.5" stop-color="#B55A5A" />
                      <stop offset="0.942708" stop-color="#922F29" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-4.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__("All Mine Gold All Mine Diamonds All Mine Diamonds")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}{" "}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="41"
                  height="36"
                  viewBox="0 0 41 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_1847_3477)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M37 17.5941V4.63366C36.9115 2.9703 35.4948 2 33.5469 2H4C5.68229 2.06931 7.30208 3.31683 7.30208 4.56436V18.3564C7.83333 24.8713 13.7396 30 22.151 30C30.9167 30 37 24.4554 37 17.5941Z"
                      fill="url(#paint0_linear_1847_3477)"
                    />
                  </g>
                  <path
                    d="M18.48 12.02V19H17.08V16.03H14.09V19H12.69V12.02H14.09V14.89H17.08V12.02H18.48ZM23.098 19.07C22.4446 19.07 21.8446 18.9167 21.298 18.61C20.7513 18.3033 20.318 17.88 19.998 17.34C19.678 16.7933 19.518 16.1767 19.518 15.49C19.518 14.81 19.678 14.2 19.998 13.66C20.318 13.1133 20.7513 12.6867 21.298 12.38C21.8446 12.0733 22.4446 11.92 23.098 11.92C23.758 11.92 24.358 12.0733 24.898 12.38C25.4446 12.6867 25.8746 13.1133 26.188 13.66C26.508 14.2 26.668 14.81 26.668 15.49C26.668 16.1767 26.508 16.7933 26.188 17.34C25.8746 17.88 25.4446 18.3033 24.898 18.61C24.3513 18.9167 23.7513 19.07 23.098 19.07ZM23.098 17.82C23.518 17.82 23.888 17.7267 24.208 17.54C24.528 17.3467 24.778 17.0733 24.958 16.72C25.138 16.3667 25.228 15.9567 25.228 15.49C25.228 15.0233 25.138 14.6167 24.958 14.27C24.778 13.9167 24.528 13.6467 24.208 13.46C23.888 13.2733 23.518 13.18 23.098 13.18C22.678 13.18 22.3046 13.2733 21.978 13.46C21.658 13.6467 21.408 13.9167 21.228 14.27C21.048 14.6167 20.958 15.0233 20.958 15.49C20.958 15.9567 21.048 16.3667 21.228 16.72C21.408 17.0733 21.658 17.3467 21.978 17.54C22.3046 17.7267 22.678 17.82 23.098 17.82ZM32.4595 12.02V13.15H30.5995V19H29.1995V13.15H27.3395V12.02H32.4595Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1847_3477"
                      x="0"
                      y="0"
                      width="41"
                      height="36"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1847_3477"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1847_3477"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_1847_3477"
                      x1="21.9981"
                      y1="2.61247"
                      x2="21.9981"
                      y2="27.9475"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.161458" stop-color="#912D28" />
                      <stop offset="0.5" stop-color="#B55A5A" />
                      <stop offset="0.942708" stop-color="#922F29" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-5.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__("All Mine Gold All Mine Diamonds All Mine Diamonds")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}{" "}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="41"
                  height="36"
                  viewBox="0 0 41 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_1847_3477)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M37 17.5941V4.63366C36.9115 2.9703 35.4948 2 33.5469 2H4C5.68229 2.06931 7.30208 3.31683 7.30208 4.56436V18.3564C7.83333 24.8713 13.7396 30 22.151 30C30.9167 30 37 24.4554 37 17.5941Z"
                      fill="url(#paint0_linear_1847_3477)"
                    />
                  </g>
                  <path
                    d="M18.48 12.02V19H17.08V16.03H14.09V19H12.69V12.02H14.09V14.89H17.08V12.02H18.48ZM23.098 19.07C22.4446 19.07 21.8446 18.9167 21.298 18.61C20.7513 18.3033 20.318 17.88 19.998 17.34C19.678 16.7933 19.518 16.1767 19.518 15.49C19.518 14.81 19.678 14.2 19.998 13.66C20.318 13.1133 20.7513 12.6867 21.298 12.38C21.8446 12.0733 22.4446 11.92 23.098 11.92C23.758 11.92 24.358 12.0733 24.898 12.38C25.4446 12.6867 25.8746 13.1133 26.188 13.66C26.508 14.2 26.668 14.81 26.668 15.49C26.668 16.1767 26.508 16.7933 26.188 17.34C25.8746 17.88 25.4446 18.3033 24.898 18.61C24.3513 18.9167 23.7513 19.07 23.098 19.07ZM23.098 17.82C23.518 17.82 23.888 17.7267 24.208 17.54C24.528 17.3467 24.778 17.0733 24.958 16.72C25.138 16.3667 25.228 15.9567 25.228 15.49C25.228 15.0233 25.138 14.6167 24.958 14.27C24.778 13.9167 24.528 13.6467 24.208 13.46C23.888 13.2733 23.518 13.18 23.098 13.18C22.678 13.18 22.3046 13.2733 21.978 13.46C21.658 13.6467 21.408 13.9167 21.228 14.27C21.048 14.6167 20.958 15.0233 20.958 15.49C20.958 15.9567 21.048 16.3667 21.228 16.72C21.408 17.0733 21.658 17.3467 21.978 17.54C22.3046 17.7267 22.678 17.82 23.098 17.82ZM32.4595 12.02V13.15H30.5995V19H29.1995V13.15H27.3395V12.02H32.4595Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1847_3477"
                      x="0"
                      y="0"
                      width="41"
                      height="36"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1847_3477"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1847_3477"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_1847_3477"
                      x1="21.9981"
                      y1="2.61247"
                      x2="21.9981"
                      y2="27.9475"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.161458" stop-color="#912D28" />
                      <stop offset="0.5" stop-color="#B55A5A" />
                      <stop offset="0.942708" stop-color="#922F29" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
            <Link className="item">
              <div className="image">
                <img
                  src={require("assets/images/v1/games/game-7.png").default}
                />
              </div>
              <div className="game_text">
                <div className="headline">
                  {__("All Mine Gold All Mine Diamonds All Mine Diamonds")}
                </div>
                <div className="game_dsc">
                  {__("Lorem ipsum dolor sit amet, consectetur")}{" "}
                </div>
              </div>
              <div className="game_badge">
                <svg
                  width="41"
                  height="36"
                  viewBox="0 0 41 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_1847_3477)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M37 17.5941V4.63366C36.9115 2.9703 35.4948 2 33.5469 2H4C5.68229 2.06931 7.30208 3.31683 7.30208 4.56436V18.3564C7.83333 24.8713 13.7396 30 22.151 30C30.9167 30 37 24.4554 37 17.5941Z"
                      fill="url(#paint0_linear_1847_3477)"
                    />
                  </g>
                  <path
                    d="M18.48 12.02V19H17.08V16.03H14.09V19H12.69V12.02H14.09V14.89H17.08V12.02H18.48ZM23.098 19.07C22.4446 19.07 21.8446 18.9167 21.298 18.61C20.7513 18.3033 20.318 17.88 19.998 17.34C19.678 16.7933 19.518 16.1767 19.518 15.49C19.518 14.81 19.678 14.2 19.998 13.66C20.318 13.1133 20.7513 12.6867 21.298 12.38C21.8446 12.0733 22.4446 11.92 23.098 11.92C23.758 11.92 24.358 12.0733 24.898 12.38C25.4446 12.6867 25.8746 13.1133 26.188 13.66C26.508 14.2 26.668 14.81 26.668 15.49C26.668 16.1767 26.508 16.7933 26.188 17.34C25.8746 17.88 25.4446 18.3033 24.898 18.61C24.3513 18.9167 23.7513 19.07 23.098 19.07ZM23.098 17.82C23.518 17.82 23.888 17.7267 24.208 17.54C24.528 17.3467 24.778 17.0733 24.958 16.72C25.138 16.3667 25.228 15.9567 25.228 15.49C25.228 15.0233 25.138 14.6167 24.958 14.27C24.778 13.9167 24.528 13.6467 24.208 13.46C23.888 13.2733 23.518 13.18 23.098 13.18C22.678 13.18 22.3046 13.2733 21.978 13.46C21.658 13.6467 21.408 13.9167 21.228 14.27C21.048 14.6167 20.958 15.0233 20.958 15.49C20.958 15.9567 21.048 16.3667 21.228 16.72C21.408 17.0733 21.658 17.3467 21.978 17.54C22.3046 17.7267 22.678 17.82 23.098 17.82ZM32.4595 12.02V13.15H30.5995V19H29.1995V13.15H27.3395V12.02H32.4595Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1847_3477"
                      x="0"
                      y="0"
                      width="41"
                      height="36"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1847_3477"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1847_3477"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_1847_3477"
                      x1="21.9981"
                      y1="2.61247"
                      x2="21.9981"
                      y2="27.9475"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.161458" stop-color="#912D28" />
                      <stop offset="0.5" stop-color="#B55A5A" />
                      <stop offset="0.942708" stop-color="#922F29" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
          </Slider>
        </div>
      </div>
    </div>
  );
}
