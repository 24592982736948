import React from "react";
import { Link } from "react-router-dom";

import Translator, { __ } from "services/Translator";

export default function PageHeadline(props) {
  return (
    <div className="page_heading">
      <div className="title">{props.page_name}</div>
      <div className="page_nav">
        <span className="text">
          <Link to="/">{__("Home page")}</Link>
        </span>
        <span className="icon">
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L6 6L1 11" stroke="#1F2933" stroke-linecap="round" />
          </svg>
        </span>
        <span className="text active">{props.page_name}</span>
      </div>
    </div>
  );
}
