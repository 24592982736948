import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";

export default function VipRewards() {
  return (
    <div className="container">
      <div className="vip_rewards">
        <div className="heading">{__("Level up your Rewards!")}</div>
        <div className="title col-lg-8 offset-lg-2">
          {__(
            "The more you play, the higher your level will grow. Reach the top with Diamond VIP status and enjoy unmatched service and extras like no other online casino offers. Get ready for the ultimate gaming adventure!"
          )}
        </div>
        <div className="reward_data">
          <div className="single_datarow">
            <div className="item"></div>
            <div className="item">
              <img src={require("assets/images/v1/vip/level/1.svg").default} />
            </div>
            <div className="item">
              <img src={require("assets/images/v1/vip/level/2.svg").default} />
            </div>
            <div className="item">
              <img src={require("assets/images/v1/vip/level/3.svg").default} />
            </div>
            <div className="item">
              <img src={require("assets/images/v1/vip/level/4.svg").default} />
            </div>
            <div className="item">
              <img src={require("assets/images/v1/vip/level/5.svg").default} />
            </div>
          </div>

          <div className="single_datarow">
            <div className="item text-left strong">{__("VIP")}</div>
            <div className="item">{__("Bronze")}</div>
            <div className="item">{__("Silver")}</div>
            <div className="item">{__("Gold")}</div>
            <div className="item">{__("Platinum")}</div>
            <div className="item">{__("Diamond")}</div>
          </div>

          <div className="single_datarow">
            <div className="item text-left strong">{__("Deposit")}</div>
            <div className="item">{__("2,500")}</div>
            <div className="item">{__("10,000")}</div>
            <div className="item">{__("20,000")}</div>
            <div className="item">{__("35,000")}</div>
            <div className="item">{__("50,000")}</div>
          </div>

          <div className="single_datarow">
            <div className="item text-left strong">{__("Cashback")}</div>
            <div className="item">-</div>
            <div className="item">-</div>
            <div className="item">{__("2.5%")}</div>
            <div className="item">{__("5%")}</div>
            <div className="item">{__("10%")}</div>
          </div>

          <div className="single_datarow d-none">
            <div className="item text-left strong">{__("Level up bonus")}</div>
            <div className="item">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>
            </div>
            <div className="item">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>
            </div>
            <div className="item">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>
            </div>
            <div className="item">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>
            </div>
            <div className="item">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00841 12.3757L3.06204 16L4.79495 9.93591L0 6.09945L6.02313 5.97569L8.00841 0L9.97687 5.97569L16 6.09945L11.205 9.93591L12.938 16L8.00841 12.3757Z"
                  fill="#4698ED"
                />
              </svg>
            </div>
          </div>

          <div className="single_datarow">
            <div className="item text-left strong">{__("Weekly Bonus")}</div>
            <div className="item">{__("Yes")}</div>
            <div className="item">{__("Yes")}</div>
            <div className="item">{__("Yes")}</div>
            <div className="item">{__("Yes")} </div>
            <div className="item">{__("Yes")} </div>
          </div>
          <div className="single_datarow">
            <div className="item text-left strong">{__("VIP Host")}</div>
            <div className="item">-</div>
            <div className="item">-</div>
            <div className="item">-</div>
            <div className="item">{__("Yes")} </div>
            <div className="item">{__("Yes")} </div>
          </div>
        </div>

        {/*Asked by client to remove the block start*/}
        <div className="heading d-none">
          {__("Do you have a VIP Status somewhare else?")}
        </div>
        <div className="title col-lg-8 offset-lg-2 d-none">
          {__(
            "If you have VIP status at any of the sites listed below – or any similar sites – let us know your current deal and we will do our best to beat it – we treat our VIPs like they have never been treated before, switch to us and we guarantee you won't be disappointed!"
          )}
        </div>
        <div className="sponsors d-none">
          <ul>
            <li>
              <Link>
                <img
                  src={require("assets/images/v1/vip/sponsor/1.svg").default}
                />
              </Link>
            </li>
            <li>
              <Link>
                <img
                  src={require("assets/images/v1/vip/sponsor/2.svg").default}
                />
              </Link>
            </li>
            <li>
              <Link>
                <img
                  src={require("assets/images/v1/vip/sponsor/3.svg").default}
                />
              </Link>
            </li>
            <li>
              <Link>
                <img
                  src={require("assets/images/v1/vip/sponsor/4.svg").default}
                />
              </Link>
            </li>
          </ul>
          <div className="clear clearfix"></div>
        </div>
        <div className="transfer d-none">
          <ul>
            <li>{__("Transfer your VIP status")}</li>
            <li>
              <Link>
                <svg
                  width="43"
                  height="16"
                  viewBox="0 0 43 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M42.7071 8.70711C43.0976 8.31658 43.0976 7.68342 42.7071 7.29289L36.3431 0.928932C35.9526 0.538408 35.3195 0.538408 34.9289 0.928932C34.5384 1.31946 34.5384 1.95262 34.9289 2.34315L40.5858 8L34.9289 13.6569C34.5384 14.0474 34.5384 14.6805 34.9289 15.0711C35.3195 15.4616 35.9526 15.4616 36.3431 15.0711L42.7071 8.70711ZM0 9H42V7H0V9Z"
                    fill="#BEEBFF"
                  />
                </svg>
              </Link>
            </li>
            <li className="logo_svg">
              <Link>
                <svg
                  width="89"
                  height="55"
                  viewBox="0 0 89 55"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1913_32439)">
                    <path
                      d="M47.8036 4.44444C48.2477 2.22222 49.0806 0 52.1342 0C55.1879 0 57.5197 2.22222 56.4648 6.66667C55.4099 11.1111 50.4131 13.3333 47.8036 17.8333C45.1941 13.3889 40.2527 11.1667 39.1423 6.66667C38.0874 2.22222 40.4193 0 43.473 0C46.5266 0 47.4149 2.22222 47.8036 4.44444Z"
                      fill="#D40000"
                    />
                    <path
                      d="M11.326 15.8331V15.1664C12.8251 14.9998 12.8806 14.8887 12.8806 13.0553V5.44423C11.4371 8.83312 9.54936 13.1109 8.43894 15.8331H7.82821C6.77331 13.1664 5.16321 9.05534 3.71967 5.61089C3.49758 11.8887 3.49758 13.222 3.49758 13.7776C3.49758 14.8331 3.94175 15.0553 5.32977 15.1664V15.8331H0.666016V15.1664C1.77643 15.0553 2.27612 14.8331 2.38716 13.722C2.49821 13.0553 2.55373 12.1109 2.99789 5.55534C3.05341 4.61089 2.88685 4.16645 1.22122 3.99978V3.38867H5.05217L8.93863 12.2776L12.9361 3.38867H16.7116V4.05534C15.157 4.22201 14.9349 4.38867 14.9904 5.83312L15.1014 13.0553C15.157 14.8887 15.2125 14.9998 16.8226 15.1664V15.8331H11.326Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M29.2593 3.11133C32.7016 3.11133 35.5887 5.66688 35.5887 9.44466C35.5887 13.5558 32.7016 16.1113 29.1483 16.1113C25.3729 16.1113 22.6523 13.3336 22.6523 9.66688C22.6523 6.33355 25.0397 3.11133 29.2593 3.11133ZM28.9817 3.88911C26.983 3.88911 25.3173 5.778 25.3173 9.22244C25.3173 12.5558 26.8164 15.278 29.4814 15.278C31.4802 15.278 33.0348 13.5002 33.0348 9.94466C32.9792 6.11133 31.3136 3.88911 28.9817 3.88911Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M54.5771 4.111V3.44434H49.8578V4.111C51.4124 4.22211 51.801 4.55545 51.8566 5.38878C51.9121 6.05545 51.9676 6.77767 51.9676 8.44434V12.3332L44.3612 3.44434H40.9189V4.111C41.7518 4.22211 42.1959 4.27767 42.5846 4.77767C42.9177 5.22211 42.9732 5.611 42.9732 6.22211V10.8888C42.9732 11.4443 42.9732 11.8888 42.9732 12.2777C43.3063 12.611 43.6395 12.8888 43.9726 13.2221C43.9726 12.7221 43.9171 11.9999 43.9171 10.8888V6.38878L50.5241 14.4443C51.3014 13.6666 52.0786 12.8888 52.8559 12.1666V8.49989C52.8559 6.77767 52.9115 6.111 52.967 5.38878C53.0225 4.49989 53.4111 4.22211 54.5771 4.111Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M71.0663 6.611C70.8442 5.83322 70.5111 5.111 70.289 4.83322C69.9558 4.38878 69.7893 4.22211 68.6789 4.22211H67.2353V13.3332C67.2353 14.8888 67.4019 15.0554 69.1785 15.1666V15.8332H63.0713V15.1666C64.7369 15.0554 64.9034 14.8888 64.9034 13.3332V4.22211H63.5154C62.4605 4.22211 62.1274 4.38878 61.7943 4.88878C61.5167 5.27767 61.2946 5.88878 61.0725 6.611H60.4062C60.5173 5.27767 60.6839 3.88878 60.6839 2.94434H61.2391C61.5167 3.38878 61.7387 3.38878 62.294 3.38878H69.9558C70.4555 3.38878 70.7331 3.27767 71.0107 2.94434H71.5104C71.5104 3.77767 71.6215 5.38878 71.677 6.55545L71.0663 6.611Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M87.7228 12.6668C87.6118 13.1668 87.1121 15.2779 86.89 15.8335H77.1738V15.2224C78.9505 15.0557 79.0615 14.9446 79.0615 13.3335V5.88905C79.0615 4.22239 78.8395 4.11127 77.507 4.00016V3.38905H83.6143C85.5575 3.38905 86.2237 3.38905 86.5013 3.3335C86.5013 3.72238 86.6124 5.27794 86.6679 6.38905L86.0016 6.50016C85.8351 5.8335 85.5575 5.22238 85.2799 4.8335C84.9468 4.38905 84.6136 4.22238 83.6143 4.22238H82.1707C81.5045 4.22238 81.4489 4.27794 81.4489 4.88905V9.00016H83.3922C84.6136 9.00016 84.7247 8.72239 85.0023 7.38905H85.6685V11.5557H84.9468C84.6691 10.1668 84.5026 9.94461 83.2811 9.94461H81.3379V13.3335C81.3379 14.6668 81.5045 14.9446 82.6149 15.0002H84.1139C85.2244 15.0002 85.502 14.8335 85.8906 14.3335C86.3348 13.8335 86.7234 13.1113 87.001 12.4446L87.7228 12.6668Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M7.71734 21.7222C11.1596 21.7222 14.0467 24.2777 14.0467 28.0555C14.0467 32.1666 11.1596 34.7222 7.6063 34.7222C3.83088 34.7222 1.11035 31.9444 1.11035 28.2777C1.11035 24.9444 3.49775 21.7222 7.71734 21.7222ZM7.43973 22.4999C5.44098 22.4999 3.77535 24.3888 3.77535 27.8333C3.77535 31.1666 5.27442 33.8888 7.93942 33.8888C9.93817 33.8888 11.4928 32.1111 11.4928 28.5555C11.4372 24.7222 9.71609 22.4999 7.43973 22.4999Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M25.8176 31.1666C25.651 31.8888 25.1513 33.9443 24.9292 34.4999H15.6572V33.8332C17.3784 33.7221 17.4894 33.5554 17.4894 31.9443V24.4999C17.4894 22.8888 17.3229 22.7221 15.8238 22.611V21.9443H21.4869V22.611C19.9879 22.7777 19.7658 22.8888 19.7658 24.4999V32.0554C19.7658 33.1666 19.8213 33.5554 20.9317 33.5554C20.9872 33.5554 22.0421 33.5554 22.0977 33.5554C23.1525 33.5554 23.4302 33.3888 23.8743 32.8888C24.263 32.4443 24.7627 31.6666 25.0403 30.8888L25.8176 31.1666Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M36.5881 22.6667C35.3111 22.8333 34.978 23.0556 34.2562 24.2222C33.4789 25.5 32.8682 26.6111 31.9243 28.5C31.7578 28.8333 31.7022 29.1111 31.7022 29.5556V31.9444C31.7022 33.6111 31.8688 33.6667 33.5344 33.8333V34.4444H27.6492V33.7778C29.2593 33.5556 29.3704 33.5 29.3704 31.8889V29.6111C29.3704 29.2222 29.3148 29 29.0928 28.6667C28.482 27.5 27.8158 26.2222 26.7054 24.2778C25.9836 23 25.706 22.7778 24.54 22.6667V22H29.8145V22.6667C28.482 22.8333 28.5375 23.1111 28.8707 23.7778C29.5369 25.1111 30.4253 26.7222 31.147 28.1111C31.7578 26.9444 32.7571 25.0556 33.2568 23.8889C33.6455 23.0556 33.5344 22.8333 32.0909 22.6667V22H36.5326V22.6667H36.5881Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M47.4148 34.4443V33.7777C48.9138 33.611 48.9693 33.4999 48.9693 31.6666V24.0554C47.5258 27.4443 45.6381 31.7221 44.5277 34.4443H43.9169C42.862 31.7777 41.2519 27.6666 39.8084 24.2221C39.5308 30.4999 39.5308 31.7777 39.5308 32.3888C39.5308 33.4443 39.975 33.6666 41.363 33.7777V34.4443H36.6992V33.7777C37.8096 33.6666 38.3093 33.4443 38.4204 32.3332C38.5314 31.6666 38.5869 30.7221 39.0311 24.1666C39.0866 23.2221 38.9201 22.7777 37.2544 22.611V21.9443H41.0854L44.9718 30.8332L48.9693 21.9443H52.7448V22.611C51.1902 22.7777 50.9681 22.9443 51.0236 24.3888L51.1347 31.611C51.1902 33.4443 51.2457 33.5554 52.8558 33.7221V34.4443H47.4148Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M59.796 21.9999C61.2395 21.9999 62.4055 22.2777 63.1827 22.7777C64.0711 23.3888 64.5708 24.2777 64.5708 25.611C64.5708 28.3888 62.1834 29.5554 60.2401 29.5554C60.0181 29.5554 59.796 29.5554 59.6849 29.4999L58.519 29.1666V31.8888C58.519 33.4999 58.63 33.5554 60.3512 33.7221V34.4443H54.5215V33.7777C56.0205 33.611 56.1871 33.4999 56.1871 31.9443V24.4999C56.1871 22.8332 55.965 22.7221 54.5215 22.611V21.9443H59.796V21.9999ZM58.4635 28.4443C58.6856 28.5554 59.1297 28.6666 59.6294 28.6666C60.6288 28.6666 62.1279 28.111 62.1279 25.611C62.1279 23.4999 60.9064 22.7221 59.5184 22.7221C59.0742 22.7221 58.7966 22.8332 58.63 22.9443C58.4635 23.0554 58.4635 23.2777 58.4635 23.6666V28.4443Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M79.2285 22.6666C78.007 22.7777 77.6184 23.111 77.5628 23.8888C77.5073 24.4443 77.5073 25.111 77.5073 26.611V28.9443C77.5073 30.7221 77.2297 32.3332 76.1748 33.4443C75.2865 34.3888 73.954 34.7221 72.6215 34.7221C71.4 34.7221 70.1786 34.4443 69.2902 33.7777C68.1798 32.9443 67.6246 31.7221 67.6246 29.4999V24.611C67.6246 22.8332 67.458 22.7221 65.959 22.611V21.9443H71.5666V22.611C70.123 22.7221 69.9565 22.8888 69.9565 24.611V29.611C69.9565 31.9999 71.1224 33.4443 73.2322 33.4443C75.6751 33.4443 76.619 31.7777 76.619 28.7777V26.5554C76.619 25.0554 76.5635 24.3888 76.508 23.8332C76.4524 22.9443 75.9527 22.7221 74.5647 22.6666V21.9999H79.284V22.6666H79.2285Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M87.2232 25.0558C86.8345 23.8335 86.3348 22.4447 84.5582 22.4447C83.3367 22.4447 82.6705 23.3336 82.6705 24.3891C82.6705 25.5558 83.4477 26.1669 85.0579 27.0002C87.1676 28.0558 88.3891 29.0558 88.3891 30.8891C88.3891 33.0558 86.5569 34.6669 83.8919 34.6669C82.6705 34.6669 81.6156 34.3335 80.7827 34.0558C80.6717 33.5558 80.3386 31.8335 80.2275 30.8891L80.8938 30.7224C81.2824 31.9447 82.2818 33.8891 84.1695 33.8891C85.391 33.8891 86.1683 33.0558 86.1683 31.8335C86.1683 30.6113 85.4465 29.9447 83.8364 29.0558C81.8376 28.0002 80.6162 27.0558 80.6162 25.1113C80.6162 23.2224 82.1152 21.6113 84.8913 21.6113C86.1128 21.6113 87.1676 21.8891 87.5563 21.9447C87.6118 22.6669 87.7229 23.5558 87.8894 24.778L87.2232 25.0558Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M12.2701 51.722C11.9924 52.6109 11.5483 53.9442 11.2707 54.5553C10.7155 54.6664 9.27192 54.9998 7.8839 54.9998C3.38671 54.9998 1.11035 52.0553 1.11035 48.7776C1.11035 44.9998 3.99744 42.3887 8.16151 42.3887C9.77161 42.3887 11.0486 42.722 11.6593 42.8331C11.7148 43.6664 11.8814 44.7776 11.9924 45.722L11.4372 45.8331C11.0486 44.6109 10.6044 43.8331 9.66057 43.4442C9.16088 43.222 8.43911 43.1109 7.71734 43.1109C4.66369 43.1109 3.05358 45.3887 3.05358 48.4442C3.05358 52.0553 4.94129 54.3331 7.93942 54.3331C9.82713 54.3331 10.771 53.4442 11.7148 51.6109L12.2701 51.722Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M24.7626 54.722V54.222C25.9841 54.0553 26.0951 53.8887 25.873 53.1664C25.6509 52.4442 25.2623 51.4998 24.8737 50.3331H20.8761C20.5985 51.1664 20.3209 51.8887 20.0433 52.6665C19.6547 53.8887 19.8768 53.9998 21.4314 54.222V54.722H17.1562V54.222C18.3222 54.0553 18.5998 53.9442 19.266 52.2776L23.097 42.4998L23.7077 42.3887C24.8737 45.5553 26.1506 49.1109 27.3166 52.3331C27.9273 53.9442 28.0939 54.1109 29.4264 54.2776V54.7776H24.7626V54.722ZM22.9304 44.7776C22.3197 46.3887 21.709 48.0553 21.1538 49.5553H24.6516L22.9304 44.7776Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M40.5861 45.4442C40.2529 44.222 39.7533 42.9998 37.9766 42.9998C36.5331 42.9998 36.0334 44.0553 36.0334 45.0553C36.0334 46.3331 36.8662 46.9998 38.4208 47.7776C40.6416 48.9442 41.641 49.7776 41.641 51.5553C41.641 53.4998 39.9753 54.9998 37.5324 54.9998C36.422 54.9998 35.4226 54.6664 34.7009 54.3887C34.5343 53.8887 34.3122 52.6109 34.2012 51.6109L34.7564 51.4442C35.145 52.6664 35.9223 54.3331 37.8655 54.3331C39.1981 54.3331 40.0309 53.3887 40.0309 52.1664C40.0309 50.722 39.2536 50.1664 37.6435 49.2776C35.7002 48.222 34.5898 47.3331 34.5898 45.6109C34.5898 43.9442 35.9778 42.3887 38.4208 42.3887C39.4757 42.3887 40.475 42.6664 40.8637 42.722C40.9192 43.3887 41.0302 44.1109 41.1413 45.3331L40.5861 45.4442Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M46.915 54.7221V54.2221C48.4141 54.0554 48.5807 53.9998 48.5807 52.3887V44.9998C48.5807 43.3887 48.4141 43.3332 46.915 43.1665V42.6665H51.9119V43.1665C50.3573 43.3332 50.2463 43.3887 50.2463 44.9998V52.3887C50.2463 53.9998 50.4129 54.0554 51.9119 54.2221V54.7221H46.915Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M70.345 43.1666C69.179 43.2777 68.7904 43.4999 68.7349 44.3333C68.7349 44.9444 68.6793 45.7222 68.6793 47.4444V54.8888H67.9576L60.0181 45.1666V49.8333C60.0181 51.4999 60.0736 52.3333 60.1291 52.8888C60.1847 53.8333 60.6288 54.1111 61.9613 54.2222V54.7222H57.4641V54.2222C58.6301 54.1111 59.0742 53.8333 59.1298 52.9444C59.1853 52.3333 59.2408 51.4999 59.2408 49.8333V45.1111C59.2408 44.6111 59.1853 44.2777 58.8522 43.8888C58.519 43.4444 58.1304 43.3333 57.1865 43.2222V42.7222H59.9626L67.8465 52.1111V47.5555C67.8465 45.8333 67.791 44.9999 67.7355 44.4444C67.68 43.6111 67.2358 43.3333 65.7367 43.2777V42.7777H70.345V43.1666Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M81.8383 42.3887C85.003 42.3887 87.8901 44.8331 87.8901 48.5553C87.8901 52.5553 85.1695 55.0553 81.7272 55.0553C78.1739 55.0553 75.5644 52.3331 75.5644 48.8331C75.5089 45.4442 77.8963 42.3887 81.8383 42.3887ZM81.4496 43.0553C79.3398 43.0553 77.4521 44.7776 77.4521 48.2776C77.4521 51.722 79.3398 54.2776 82.0048 54.2776C84.2812 54.2776 85.9468 52.6109 85.9468 48.9998C85.9468 45.1109 83.8926 43.0553 81.4496 43.0553Z"
                      fill="#BEEBFF"
                    />
                    <path
                      d="M89 38.3887H0V39.0553H89V38.3887Z"
                      fill="#BEEBFF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1913_32439">
                      <rect width="89" height="55" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Link>
            </li>
            <li>
              <Link className="common_btn_v1 uppercase fit_width">
                {__("Coming soon")}
              </Link>
            </li>
          </ul>
          <div className="clear clearfix"></div>
        </div>
        {/*Asked by client to remove the block end*/}

        <div className="rekeback_block">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-2 text-center ">
              <img
                className="margin_top_15"
                src={require("assets/images/v1/vip/rakeback.svg").default}
              />
            </div>
            <div className="col-lg-10">
              <div className="heading text-left">
                {__("Enjoy Instant Rakeback on all your Bets!")}
              </div>
              <div className="title text-left">
                {__(
                  "Don’t want to wait a week to get your rakeback? Never fear, we hear you! At Casino Monte Olympus, if you deposit and lose your deposit, get instant rakeback added to your account from that deposit giving you a second chance to win – why wait for rakeback when you can get it instantly?"
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="heading">
          {__("Weekly Reloads Skyrocket your Winning Chances!")}
        </div>
        <div className="title col-lg-8 offset-lg-2">
          {__(
            "On top of our standard weekly promotions, such as Tasty Tuesdays (75% reload bonus) and Free Spins Thursdays (free spins depending on 1st deposit amount of the day), VIPs at Casino Monte Olympus are entitled to bigger and better reloads and much more besides!"
          )}
        </div>

        <div className="slots">
          <div className="row">
            <div className="col-lg-6 text-center">
              <img src={require("assets/images/v1/vip/slots.svg").default} />
              <div className="heading_colored">
                {__("Over 5,000 great games to choose from")}
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <img src={require("assets/images/v1/vip/calender.svg").default} />
              <div className="heading_colored">
                {__("Weekly and Monthly VIP only promotions and races")}
              </div>
            </div>
          </div>
        </div>

        {/*Asked by client to remove block start*/}
        <div className="heading text-left d-none">
          {__("Casino Monte Olympus Lucky Wheel!")}
        </div>
        <div className="title text-left d-none">
          {__(
            "A Prize Guaranteed every day you don't win! How's your luck? At Casino Monte Olympus, we want to give you the chance to be lucky, even when you're not! Each day you play and don't win, you will be able to spin the Casino Monte Olympus Cashback Wheel the next day, from 00.00 to 23.59 CET. The amount you turnover determines the tier from which you will spin the wheel, there is a Diamond, Gold, Silver and Bronze one. You will be able to win a % of cashback on your previous day's deposits, from 0.1% up to 10% - On top of the standard 10% weekly cashback every Monday."
          )}
        </div>

        <div className="wheel_table d-none">
          <div className="single_datarow table_head">
            <div className="item text-left">{__("Tier")}</div>
            <div className="item">{__("Diamond")}</div>
            <div className="item">{__("Gold")}</div>
            <div className="item">{__("Silver")}</div>
            <div className="item">{__("Bronze")}</div>
          </div>
          <div className="single_datarow">
            <div className="item text-left">{__("Wagering requirements")}</div>
            <div className="item">{__("$0 - $10 000")}</div>
            <div className="item">{__("$0 - $10 000")}</div>
            <div className="item">{__("$0 - $10 000")}</div>
            <div className="item">{__("$0 - $10 000")}</div>
          </div>
          <div className="single_datarow">
            <div className="item text-left">{__("Rakeback")}</div>
            <div className="item">{__("10%")}</div>
            <div className="item">{__("10%")}</div>
            <div className="item">{__("10%")}</div>
            <div className="item">{__("10%")}</div>
          </div>
          <div className="single_datarow">
            <div className="item text-left">{__("Level up bonus")}</div>
            <div className="item">{__("")}</div>
            <div className="item">{__("")}</div>
            <div className="item">{__("")}</div>
            <div className="item">{__("")}</div>
          </div>
          <div className="single_datarow">
            <div className="item text-left">{__("Weekly Bonus")}</div>
            <div className="item">{__("-")}</div>
            <div className="item">{__("-")}</div>
            <div className="item">{__("yes")}</div>
            <div className="item">{__("yes")}</div>
          </div>
          <div className="single_datarow">
            <div className="item text-left">{__("VIP Host Bonus")}</div>
            <div className="item">{__("-")}</div>
            <div className="item">{__("-")}</div>
            <div className="item">{__("-")}</div>
            <div className="item">{__("yes")}</div>
          </div>
        </div>

        <div className="title text-left d-none">
          {__(
            "All winnings are cash and are based on net loss from the previous days play. Winnings will be added to accounts instantly once the spin has completed.Standard promotional terms apply, Casino Monte Olympus reserves the right to change, modify or remove this promotion at any time without giving a reason."
          )}
        </div>
      </div>
    </div>
  );
}
