import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
import CategoryGames from "elements/games_elements/CategoryGames";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "local-storage";
import { Badge, Table, Modal } from "react-bootstrap";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import VipLevelText from "elements/VipLevelText";
import SingleCategoryGames from "elements/games_elements/SingleCategoryGames";
import Translator, { __ } from "services/Translator";
import SearchBox from "../elements/SearchBox";

export default function GamePlay(props) {
  const [gameList, setGameList] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);

  const getGameList = async () => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: 5,
      game_types: ["popular"],
      page: 1,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.popular?.gamelist ?? []);
    }
  };

  const searchTextBox = useRef(null);

  const focusSearchBar = () => {
    searchTextBox.current.focus();
  };

  // const __ = (text) => text;

  useEffect(() => {
    getGameList();
  }, [gameSorting, props.selectedProviders]);

  const params = useParams();
  const history = useHistory();
  const isAuthenticated = props.isAuthenticated;
  const [launchUrl, setLaunchUrl] = useState("");
  const [gameDetails, setGameDetails] = useState("");
  const [gamePlayMode, setGamePlayMode] = useState(params.mode ? params.mode : "real");
  const [gameSessionId, setGameSessionId] = useState(null);

  const toggleFullScreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      var element = document.getElementById("game_play_window");
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const iframeSrcChanged = (event) => {
    // alert(event.target.contentWindow.location);
  };

  const getPlay = async () => {
    if (params.slug === "sportsbook" || params.slug === "esports") {
      history.push("/sports");
      return;
    }
    var response = await api.post("/game/initsession", {
      slug: params.slug,
      mode: gamePlayMode,
    });

    if (response.status === 200 && response.data) {
      setLaunchUrl(response.data.launchUrl);
      setGameSessionId(response.data.sessionId);
      setGameDetails(response.data.details);
    } else {
      props.showAlert("Warning!!", response.data.message, true, () => {
        // history.push("/play/" + params.slug + "/demo");
      });
    }
  };

  useEffect(() => {
    getPlay();
  }, [gamePlayMode, params.slug]);

  const [isFavorite, setIsFavorite] = useState(gameDetails.is_favorite_game);
  const toggleFavoriteGame = async () => {
    alert(gameDetails.is_favorite_game);
    var response = await api.post("/toggle-favorite-game", {
      gameId: gameDetails.id,
    });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  const [bonusUnsupportedWarningModal, setBonusUnsupportedWarningModal] = useState({ show: false });

  useEffect(() => {
    if (
      gameDetails &&
      props.userBalance &&
      gamePlayMode !== "demo" &&
      !gameDetails.bonus_supported &&
      parseFloat(props.userBalance.cash_balance) === 0.0
    ) {
      setBonusUnsupportedWarningModal({ show: true });
    } else {
      setBonusUnsupportedWarningModal({ show: false });
    }
  }, [gamePlayMode, props.userBalance.cash_balance, gameDetails]);

  const closeAllModals = () => {
    setGameHubMessageModal({ show: false });
    setBonusUnsupportedWarningModal({ show: false });
  };

  //GameHub message functionality
  const [gameHubMessageModal, setGameHubMessageModal] = useState({
    title: "",
    message: "",
    show: false,
  });

  const loadGameHubMessage = async () => {
    var response = await api.post("/load-game-hub_message", {
      provider: gameDetails.game_aggregator,
      game_session_id: gameSessionId,
    });
    if (response.status === 200) {
      if (response.data.type == "found") {
        //There is a message to show.
        var modalData = {
          ...modalData,
          show: true,
          title: __("Warning!!"),
          message: response.data.gamehub_message.message,
          message_type: response.data.gamehub_message.message_type,
        };

        setGameHubMessageModal(modalData);
      } else if (response.data.type == "session_created") {
        //Session type changed. Message to show and game area to reload
        setGameHubMessageModal({
          ...gameHubMessageModal,
          message_type: response.data.gamehub_message.message_type,
          title: __("Balance type changed!"),
          message: response.data.gamehub_message.message,
          show: true,
        });
        setGameSessionId(response.data.newSession.sessionId);
        setLaunchUrl(response.data.newSession.launchUrl);
      }
    }
  };

  useEffect(() => {
    var interval = setInterval(function () {
      if (gamePlayMode !== "demo") {
        loadGameHubMessage();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [gameSessionId, gameDetails, isAuthenticated, gamePlayMode]);

  //GamePlay Page detection
  const isMobileDevice = props.isMobileDevice;
  const isMobileDeviceByViewPort = props.isMobileDeviceByViewPort;
  const isLandscape = props.isLandscape;
  const [mobileIframeStyle, setMobileIframeStyle] = useState({});
  const calclateMobileIframeStyle = () => {
    var style = {
      width: "100vw",
      height: "calc(100vh - 52px)",
    };
    if (isLandscape) {
      style.height = "100vh";
    }
    setMobileIframeStyle(style);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      calclateMobileIframeStyle();
    });
    calclateMobileIframeStyle();

    window.gotoGamesPage = () => {
      history.push("/");
    };
  }, []);

  const [topWinners, setTopWinners] = useState([]);

  const getTopWinners = async () => {
    var response = await api.get("/games/top-winners?limit=5");
    if (response.status === 200) {
      setTopWinners(response.data.data);
    } else {
      console.log(response.data);
    }
  };
  const [detailsTab, setDetailsTab] = useState("Description");
  const [theaterMode, setTheaterMode] = useState(false);
  useEffect(() => {
    getTopWinners();
    // if(isAuthenticated){
    //   setGamePlayMode('real');
    // } else {
    //   setGamePlayMode('demo');
    // }
  }, []);

  const [maximumWagersPerRoundSettings, setMaximumWagersPerRoundSettings] = useState([]);
  const getMaximumWagersPerRoundSettings = async () => {
    var response = await api.post("/maximum-wagers-per-round-settings", {
      type: "Cash",
    });
    if (response.status === 200) {
      setMaximumWagersPerRoundSettings(response.data);
      // console.log('maximumWagersPerRoundSettings', response.data);
    }
  };

  const [maximumBonusWagersPerRoundSettings, setMaximumBonusWagersPerRoundSettings] = useState([]);
  const getMaximumBonusWagersPerRoundSettings = async () => {
    var response = await api.post("/maximum-wagers-per-round-settings", {
      type: "Bonus",
    });
    if (response.status === 200) {
      setMaximumBonusWagersPerRoundSettings(response.data);
      // console.log('maximumWagersPerRoundSettings', response.data);
    }
  };

  useEffect(() => {
    getMaximumWagersPerRoundSettings();
    getMaximumBonusWagersPerRoundSettings();
  }, []);

  return (
    <>
      {!isMobileDevice && (
        <>
          <div className="container">
            <div className={"game_play " + (theaterMode ? "theaterModeEnabled" : "")}>
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className={"game_content "}>
                    <div className="game_head">
                      <div className="game_title">{gameDetails.game_name ? gameDetails.game_name : "Game Name"}</div>
                      <div className="game_title">12:30</div>
                    </div>
                    <iframe
                      style={{ display: "block !important;" }}
                      id="game_play_window"
                      width="100%"
                      height="100%"
                      src={launchUrl}
                    ></iframe>
                    <div className="clear clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "47px" }}>
                <div className="col-12">
                  <div className="frame_bottom">
                    <div className="actions_btn">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitches"
                          defaultChecked={gamePlayMode == "real" ? true : false}
                          onChange={(ev) => {
                            var target = ev.target;
                            if (target.checked) {
                              setGamePlayMode("real");
                            } else {
                              setGamePlayMode("demo");
                            }
                          }}
                        />
                        <label class="custom-control-label" for="customSwitches">
                          {__("Real Play")}
                        </label>
                      </div>
                    </div>

                    <div className="text_area">
                      {isAuthenticated && (
                        <i onClick={toggleFavoriteGame} className={(isFavorite ? "fas" : "far") + " fa-heart"}></i>
                      )}{" "}
                      {gameDetails.game_name ? gameDetails.game_name : "Game Name"}
                    </div>
                    <div className="actions_icon">
                      <SearchBox />

                      <OverlayTrigger overlay={<Tooltip>{__("Full-Screen")}</Tooltip>}>
                        <Link onClick={toggleFullScreen} hrefLang={Translator.getSelectedLanguage().code}>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.5996 0.599609H20.5996V5.59961M20.5996 15.5996V20.5996H15.5996M5.59961 20.5996H0.599609V15.5996M0.599609 5.59961V0.599609H5.59961"
                              stroke="#B1C1D3"
                              stroke-width="1.2"
                              stroke-miterlimit="83.3333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Link>
                      </OverlayTrigger>
                      <Link hrefLang={Translator.getSelectedLanguage().code}>
                        <OverlayTrigger className="tooltipStyle" overlay={<Tooltip>{__("Theater Mode")}</Tooltip>}>
                          <svg
                            onClick={() => {
                              if (theaterMode) {
                                setTheaterMode(false);
                              } else {
                                setTheaterMode(true);
                              }
                            }}
                            width="30"
                            height="9"
                            viewBox="0 0 30 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.599609 4.09961H8.39961M0.599609 4.09961L4.09961 7.69961M0.599609 4.09961L4.09961 0.599609M28.7996 4.09961H20.9996M28.7996 4.09961L25.2996 0.599609M28.7996 4.09961L25.2996 7.59961"
                              stroke="#B1C1D3"
                              stroke-width="1.2"
                              stroke-miterlimit="83.3333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </OverlayTrigger>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <CategoryGames
            {...props}
            gameList={gameList}
            categoryName="Popular Games"
            category="popular"
            setGameSorting={setGameSorting}
            gameSorting={gameSorting}
          />
        </>
      )}

      {isMobileDevice && <iframe onLoad={iframeSrcChanged} src={launchUrl} style={mobileIframeStyle} />}

      <Modal className="gamePlayMsgModal" show={gameHubMessageModal.show} onHide={closeAllModals}>
        {/* <Modal className="gamePlayMsgModal" show={1} onHide={closeAllModals}> */}
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {gameHubMessageModal.message_type == "maximum_cash_wagers_per_round"
              ? // __("Maximum cash wager limit exceeded!")
                __("Uh oh...")
              : gameHubMessageModal.message_type == "maximum_bonus_wagers_per_round"
              ? __("Maximum bonus wagers limit exceeded!")
              : gameHubMessageModal.message_type == "bonus_balance_not_support"
              ? __("Insufficient balance!")
              : gameHubMessageModal.message_type == "maximum_wagers"
              ? __("Maximum cash wager limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_loss"
              ? __("Maximum cash loss limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_playing_time"
              ? __("Maximum play time limit exceeded!")
              : gameHubMessageModal.title}
          </h5>
          {/* <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModals}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            <i class="fas fa-exclamation-triangle"></i>
            <br />
            {gameHubMessageModal.message_type == "maximum_cash_wagers_per_round" ? (
              <>
                {/* You have exceeded the maximum bet amount for cash money. Please
                lower the amount and try again. See our{" "}
                <Link hrefLang={Translator.getSelectedLanguage().code} to="">General Terms & Conditions</Link> for more
                information. */}
                {__("Opening month betting limits apply")}
                <br />
                {__("Max Bet")} $
                {gameDetails.type == "video-slot"
                  ? maximumWagersPerRoundSettings != ""
                    ? maximumWagersPerRoundSettings.slots.limit_amount
                    : ""
                  : gameDetails.type == "Live Casino"
                  ? maximumWagersPerRoundSettings != ""
                    ? maximumWagersPerRoundSettings.live_casinos.limit_amount
                    : ""
                  : gameDetails.type == "livecasino"
                  ? maximumWagersPerRoundSettings != ""
                    ? maximumWagersPerRoundSettings.live_casinos.limit_amount
                    : ""
                  : maximumWagersPerRoundSettings != ""
                  ? maximumWagersPerRoundSettings.others.limit_amount
                  : ""}
                <br />
                <br />
                <span style={{ fontSize: "14px" }}>{__("Unlimited bets from May 19")}</span>
              </>
            ) : gameHubMessageModal.message_type == "maximum_bonus_wagers_per_round" ? (
              <>
                {/* You have exceeded the maximum bet amount for bonus money. Please
                lower the amount and try again. See our{" "} */}
                {__("Opening month betting limits apply")}
                <br />
                {__("Max Bet")} $
                {gameDetails.type == "video-slot"
                  ? maximumBonusWagersPerRoundSettings != ""
                    ? maximumBonusWagersPerRoundSettings.slots.limit_amount
                    : ""
                  : gameDetails.type == "Live Casino"
                  ? maximumBonusWagersPerRoundSettings != ""
                    ? maximumBonusWagersPerRoundSettings.live_casinos.limit_amount
                    : ""
                  : gameDetails.type == "livecasino"
                  ? maximumBonusWagersPerRoundSettings != ""
                    ? maximumBonusWagersPerRoundSettings.live_casinos.limit_amount
                    : ""
                  : maximumBonusWagersPerRoundSettings != ""
                  ? maximumBonusWagersPerRoundSettings.others.limit_amount
                  : ""}
                <Link hrefLang={Translator.getSelectedLanguage().code} to="">
                  {__("Bonus Terms")}
                </Link>{" "}
                {__("for more information")}.
              </>
            ) : gameHubMessageModal.message_type == "bonus_balance_not_support" ? (
              <>
                {__(
                  "Bonus play is not supported for this game. You do not have sufficient cash balance to play this game"
                )}
                . {__("See our")}{" "}
                <Link hrefLang={Translator.getSelectedLanguage().code} to="">
                  {__("Bonus Terms")}
                </Link>{" "}
                {__("for more information")}. {__("Note")}:{" "}
                {__("For Live Casino games and Sports Betting, Only cash wagers are accepted")} ({__("no bonus wagers")}
                )
              </>
            ) : (
              <>{gameHubMessageModal.message}</>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="common_btn" onClick={closeAllModals}>
            OK
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={bonusUnsupportedWarningModal.show} onHide={closeAllModals}>
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {__("Game playing with bonus money!")}
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeAllModals}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            {__("This game is not available for bonus money. Only cash money is accepted.")}
            <br />
            {__("Note: All Live Casino games are not available for bonus money wagering.")}
            <br />
            <Link hrefLang={Translator.getSelectedLanguage().code} to="/bonus">
              {__("Click here")}
            </Link>{" "}
            {__("to see which games are available for Bonus Money playing.")}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
