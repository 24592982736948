import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link, useHistory, useParams } from "react-router-dom";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "local-storage";
import { Badge, Table, Collapse, Modal } from "react-bootstrap";

import CategoryGameSlider from "elements/games_elements/CategoryGameSlider";
import CategoryGames from "elements/games_elements/CategoryGames";
import SingleCategoryGames from "elements/games_elements/SingleCategoryGames";
import GamesFilter from "elements/games_elements/GamesFilter";
import Slider from "react-slick";
import Banner from "elements/Banner";
import BannerMobile from "elements/BannerMobile";
import SearchBox from "elements/SearchBox";
import ProviderFilter from "elements/ProviderFilter";
import Translator, { __ } from "services/Translator";
// new design_v1
import HomeBanner from "elements/home/HomeBanner";
import HomeSlider from "elements/home/HomeSlider";
import GameList from "elements/games_elements/GameList";
import BottomBanner from "elements/home/BottomBanner";
import Winner from "elements/home/Winner";

export default function Landing(props) {
  const params = useParams();
  const [gameList, setGameList] = useState([]);
  const history = useHistory();
  const closeAllModals = () => {
    history.push("/");
  };

  const authResult = new URLSearchParams(window.location.search);
  const afftoken = authResult.get("afftoken");

  useEffect(() => {
    if (afftoken) {
      ls.set("afftoken", afftoken);
      // history.push("/");
    }
  }, [afftoken]);

  const justActivated = props.activated ? true : false;

  const [gameSorting, setGameSorting] = useState(null);
  const [pagination, setPagination] = useState(null);
  const getGameList = async (page) => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: 21,
      game_types: ["popular"],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.popular?.gamelist ?? []);

      if (page === 1) {
        setGameList(response.data.games?.popular?.gamelist ?? []);
      } else {
        var gamesClone = JSON.parse(JSON.stringify(gameList));
        console.log("gamesClone", gamesClone);
        gamesClone = [...gamesClone, ...response.data.games["popular"].gamelist];
        setGameList(gamesClone);
      }

      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const onPageChange = (page) => {
    getGameList(page);
  };

  useEffect(() => {
    getGameList(1);
  }, [gameSorting, props.selectedProviders]);

  // expand searcbar

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchTextBox = useRef(null);

  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      console.log("getSearchSuggestions", response.data);
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);

  const doSearchByGameName = (text) => {
    setSearchStr("");
    props.setSearchStr(text);
    setSearchSuggestions([]);
    toggleExpandedSearchBar(true);
    history.push("/games/search");
  };

  const [visible, setVisible] = useState(true);
  const hideNoImageFoundGames = () => {
    setVisible(false);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [searchFocus, setSearchFocus] = useState(false);
  useEffect(() => {
    console.log("PROPS", props);
    if (props.location.state && props.location.state.focusSearch) {
      setSearchFocus(true);
      document.getElementById("searchFocusInput").focus();
    } else {
      setSearchFocus(false);
    }
  }, [params]);

  //Newsletter section
  const [subscribeEmail, setSubscribeEmail] = useState("");

  const newsletterSubscription = async (ev) => {
    ev.preventDefault();
    var valid = true;
    if (subscribeEmail.length < 2 || subscribeEmail.length > 250) {
      props.showAlert("Warning!!", __("Please enter your email address"));
      valid = false;
    }
    if (!emailValidate(subscribeEmail)) {
      props.showAlert("Warning!!", __("Please enter your email address"));
      valid = false;
    }

    if (valid) {
      var response = await api.post("/newsletter-subscription", {
        subscribeEmail: subscribeEmail,
      });

      if (response.status === 200) {
        props.showAlert("Success!!", response.data.message);
        setSubscribeEmail("");
      } else {
        props.showAlert("Warning!!", response.data.message, true);
      }
    }
  };

  const checkIframe = () => {
    if (window.parent.parent.location.href != window.location.href) {
      window.parent.location.href = window.location.href;
    } else {
    }
  };

  useEffect(async () => {
    checkIframe();
  }, []);

  const [winnerTab, setWinnerTab] = useState("Recent Winners");
  const [recentWinners, setRecentWinners] = useState([]);
  const getRecentWinners = async () => {
    var response = await api.get("/games/recent-winners", { limit: 7 });
    if (response.status === 200) {
      setRecentWinners(response.data.data);
      // console.log('response.data', response.data);
    }
  };

  const [topWinners, setTopWinners] = useState([]);
  const getTopWinners = async () => {
    var response = await api.get("/games/top-winners", { limit: 7 });
    if (response.status === 200) {
      setTopWinners(response.data.data);
    }
  };

  useEffect(async () => {
    getTopWinners();
    getRecentWinners();
  }, []);

  return (
    <div className="landing">
      <HomeBanner {...props} />
      <HomeSlider {...props} />
      <GamesFilter {...props} />
      <CategoryGames
        {...props}
        gameList={gameList}
        // categoryName="Popular Games"
        category="all"
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
      />
      {/* <GameList /> */}
      <BottomBanner {...props} />
      <Winner {...props} />
    </div>
  );
}
