import React, { useState, useEffect } from "react";
import api from "services/api";
import auth from "services/auth";
import ScrollTo from "elements/ScrollTo";
import Header from "elements/Header";
import Footer from "elements/Footer";
import Spinner from "elements/Spinner";
import swal from "sweetalert";
import Chat from "elements/Chat";
import ls from "services/ls";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import { isMobile, isMobileByViewPort, cauculateLandScape } from "services/isMobile";
import LeftSide from "elements/LeftSide";
import LoginReg, { loginData } from "elements/LoginReg";
import StickyFooterMenu from "elements/StickyFooterMenu";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const FullLayout = ({ children, ...rest }) => {
  const location = useLocation();

  const [spinner, setSpinner] = useState(false);

  const [isLandscape, setIsLandscape] = useState(cauculateLandScape());

  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMobileDeviceByViewPort, setIsMobileDeviceByViewPort] = useState(false);

  const showAlert = (title, message, isError, callback) => {
    swal({
      title: title,
      text: message,
      className: theme,
      icon: isError ? "warning" : "success",
    }).then((value) => {
      if (callback) {
        callback(value);
      }
    });
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userDetails, setUserDetails] = useState(auth.getUser);

  //Get user balance functionality
  const [userBalance, setUserBalance] = useState({
    cash_balance: 0,
    bonus_balance: 0,
    total_balance: 0,
    wallets: [],
    rates: [],
  });
  const getUserBalance = async (ev) => {
    var response = await api.get("/get-balance");
    console.log(response);
    ls.set("conversionRates", response.data.rates);
    setUserBalance(response.data);
  };

  const setUsersWallet = async (currency) => {
    var response = await api.post("/set-wallet", { currency });
    console.log(response);
    if (response.status === 200) {
      setUserBalance(response.data);
      ls.set("conversionRates", response.data.rates);
    }
  };

  const [accountProgress, setAccountProgress] = useState(0);
  const getAccountProgress = async (ev) => {
    var response = await api.get("/account-progress");
    console.log("accountProgress", response);
    setAccountProgress(response.data);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsLandscape(cauculateLandScape());
    });
    setIsMobileDevice(isMobile());
    setIsMobileDeviceByViewPort(isMobileByViewPort());

    var interval;
    if (isAuthenticated) {
      getAccountProgress();
      getUserBalance();
      setUserDetails(auth.getUser());
      getNotificationsCount();
      getMessageCount();
    }

    interval = setInterval(() => {
      if (isAuthenticated) {
        getUserBalance();
        getNotificationsCount();
        getMessageCount();
      }
    }, 15000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  const [searchStr, setSearchStr] = useState(""); //Used in Header.js and Games.js
  const [providers, setProviders] = useState([]); //Used in Header.js and Games.js
  const [selectedProviders, setSelectedProviders] = useState([]); //Used in Header.js and Games.js
  const getProviders = async () => {
    var response = await api.get("/games/providers");
    if (response.status === 200) {
      setProviders(response.data);
    }
  };
  const [initialSettings, setInitialSettings] = useState([]);
  const getInitialSettings = async () => {
    var response = await api.get("/initial-settings");
    if (response.status === 200) {
      setInitialSettings(response.data);
    }
  };
  useEffect(() => {
    getProviders();
    getInitialSettings();
  }, []);

  const [supportSidebarOpen, setSupportSidebarOpen] = useState(false);
  const [modalSignUpPropsData, setModalSignUpPropsData] = useState(false);
  const [modalLoginPropsData, setModalLoginPropsData] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);

  const [openWalletModalTab, setOpenWalletModalTab] = useState(null);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [chatInPopup, setChatInPopup] = useState(null);

  const [metamaskConnected, setMetamaskConnected] = useState(false);
  const [theme, setTheme] = useState(ls.get("theme") ? ls.get("theme") : "light");

  const [isRacesPageActive, setIsRacesPageActive] = useState(false);
  const getIsRacesPageActive = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "races",
    });
    if (response.status === 200) {
      setIsRacesPageActive(true);
    }
  };
  useEffect(() => {
    getIsRacesPageActive();
  }, []);

  const [showVaultModal, setShowVaultModal] = useState(false);

  const [notificationCount, setNotifictionCount] = useState(0);
  const getNotificationsCount = async (ev) => {
    var response = await api.get("/player/notifications-count");
    if (response.status === 200) {
      setNotifictionCount(response.data);
    }
  };

  const [messageCount, setMessageCount] = useState(0);
  const getMessageCount = async (ev) => {
    var response = await api.get("/player/message-count");
    if (response.status === 200) {
      setMessageCount(response.data);
    }
  };

  const passableParams = {
    messageCount,
    getMessageCount,
    setMessageCount,
    notificationCount,
    getNotificationsCount,
    setNotifictionCount,
    showVaultModal,
    setShowVaultModal,
    isRacesPageActive,
    showAlert,
    isMobileDevice,
    isMobileDeviceByViewPort,
    isLandscape,
    isAuthenticated,
    setSpinner,
    spinner,
    userDetails,
    userBalance,
    getUserBalance,
    setUsersWallet,
    accountProgress,
    getAccountProgress,
    searchStr,
    setSearchStr,
    providers,
    selectedProviders,
    setSelectedProviders,
    supportSidebarOpen,
    setSupportSidebarOpen,
    modalSignUpPropsData,
    setModalSignUpPropsData,
    modalLoginPropsData,
    setModalLoginPropsData,
    showLeftSidebar,
    setShowLeftSidebar,
    openWalletModalTab,
    setOpenWalletModalTab,
    isChatVisible,
    setIsChatVisible,
    initialSettings,
    metamaskConnected,
    setMetamaskConnected,
    setTheme,
    theme,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  const [inPlay, setInPlay] = useState(false);
  const playPath = location.pathname.split("/");

  useEffect(() => {
    setShowLeftSidebar(false);
  }, [location.pathname]);

  useEffect(() => {
    setInPlay(playPath.includes("play"));
  }, [playPath]);
  return (
    <>
      {spinner && <Spinner />}
      <GoogleReCaptchaProvider reCaptchaKey="6LeS_4MfAAAAAFLVe_-eLMC-ozusZM6VtZm_w3NP">
        <LoginReg {...passableParams} />
        <div className={`home ${theme} ${isChatVisible && isAuthenticated ? "chat_open" : ""}`}>
          {(isMobileDevice && inPlay) || <Header {...passableParams} />}
          <div className="main_body_v1">
            <div className="left_bar_v1">
              <LeftSide {...passableParams} />
            </div>
            <div className="right_bar_v1">
              <main>{childrenWithProps}</main>
              {(isMobileDevice && inPlay) || <Footer {...passableParams} />}
            </div>
          </div>
        </div>
        {isMobileDevice && (!inPlay || !isLandscape) && <StickyFooterMenu {...passableParams} />}

        <ScrollTo />
        {isAuthenticated && <Chat {...passableParams} chatInPopup={chatInPopup} setChatInPopup={setChatInPopup} />}
      </GoogleReCaptchaProvider>
    </>
  );
};

export default FullLayout;
