import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
export default function HomeBanner(props) {
  return (
    <div className="home_banner_v1">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="earn_block">
              <div className="earn_headline">
                {__("Get up to")} <span className="colored">{__("$2,500")} </span>
                {__("bonus")}
                <br />
                {__(" ")}
                <span className="colored">+{__("300")} </span>
                {__("Free Spins")}
              </div>
              <div className="earn_text">
                {__("Unleash a whirlwind of excitement with our unbeatable Welcome Bonus Package.")}
              </div>
              <div className="register_link">
                {!props.isAuthenticated && (
                  <Link
                    onClick={() => {
                      props.setModalSignUpPropsData(true);
                      setTimeout(function () {
                        props.setModalSignUpPropsData(false);
                      }, 500);
                    }}
                    className="common_btn_v1 fit_width red text-white"
                  >
                    {__("Create Account")}
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="header_block">
              <div className="header_block_headline">{__("Monthly Slots Sprint Spectacular has started!")}</div>
              <div className="header_text">
                {__("Spin into action and race against other players to win")}
                <br />
                {__("big in our thrilling casino slot showdown.")}
                <br />
                {__("Don't miss out on the superb prizes...")}
              </div>

              <div className="card_clock_area">
                <div className="clock_heading">{__("Time left to the finish")}</div>
                <div className="clock">
                  <div className="item">
                    <div className="box">12</div>
                    <div className="text">{__("Hours")}</div>
                  </div>
                  <div className="item colon">:</div>
                  <div className="item">
                    <div className="box">48</div>
                    <div className="text">{__("Minutes")}</div>
                  </div>
                  <div className="item colon">:</div>
                  <div className="item">
                    <div className="box">20</div>
                    <div className="text">{__("Seconds")}</div>
                  </div>
                </div>
              </div>

              <div className="header_block_flag d-none d-md-block">
                <img src={require("assets/images/v1/banner/banner-flag.png").default} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="offer_block">
              <div className="offer_heading">
                {__("Spin-tastic Signup Surprise:")}
                <br />
                {__("10 Free Spins!")}
              </div>
              <div className="offer_text">
                {__("Jump-start your winning streak with a blast of fun.")} <br />
                {__("Sign up today and grab 10 Free Spins to ignite your")}
                <br />
                {__("Casino Monte Olympus experience!")}
              </div>
              <div className="offer_link">
                {!props.isAuthenticated && (
                  <Link
                    onClick={() => {
                      props.setModalSignUpPropsData(true);
                      setTimeout(function () {
                        props.setModalSignUpPropsData(false);
                      }, 500);
                    }}
                    className="common_btn_v1 fit_width text-white"
                  >
                    {__("Register")}
                  </Link>
                )}
              </div>
              <div className="vector d-none d-md-block">
                <img src={require("assets/images/v1/banner/vector-offer-1.svg").default} />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="offer_block change_bg">
              <div className="offer_heading">
                {__("Olympus Rising Reward:")}
                <br />
                {__("200% Bonus & 100 Free Spins!")}
              </div>
              <div className="offer_text">
                {__("Unleash the power of Casino Monte Olympus with a ")} <br />
                {__("Colossal 200% bonus and 100 Free Spins on your ")}
                <br />
                {__("First deposit. Let the games begin!")}
                <br /> <br />{" "}
              </div>
              <div className="offer_link">
                {!props.isAuthenticated && (
                  <Link
                    onClick={() => {
                      props.setModalSignUpPropsData(true);
                      setTimeout(function () {
                        props.setModalSignUpPropsData(false);
                      }, 500);
                    }}
                    className="common_btn_v1 fit_width text-white"
                  >
                    {__("Register")}
                  </Link>
                )}
              </div>
              <div className="vector change_area d-none d-md-block">
                <img src={require("assets/images/v1/banner/vector-offer-2.svg").default} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
