import React, { useEffect, useState } from "react";
import { Button, Modal, Table, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import NoRecordsFound from "elements/NoRecordsFound";
import GameItem from "elements/games_elements/GameItem";
import LoadMore from "elements/LoadMore";
import api from "services/api";
import moment from "moment";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../elements/PageHeadline";

export default function Races(props) {
  const [showDetails, setShowDetails] = useState(false);
  const [raceTab, setRaceTab] = useState("In Progress");
  const [showLeaderBoard, setShowLeaderBoard] = useState(false);

  const [races, setRaces] = useState([]);
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState("");
  const [campaignGames, setCampaignGames] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);

  const getRaces = async (raceStatus) => {
    var response = await api.get("/race/list/" + raceStatus);
    if (response.status === 200) {
      setRaces(response.data);
    }
  };

  useEffect(() => {
    if (raceTab === "In Progress") {
      getRaces("active");
    } else {
      getRaces("inactive");
    }
  }, [raceTab]);

  const openLeaderBoard = async (id) => {
    setShowLeaderBoard(true);
    var response = await api.get("/race/leaderboard/" + id);
    if (response.status === 200) {
      setLeaderBoard(response.data);
    }
  };

  const openDetails = async (campaign) => {
    setShowDetails(true);
    setCampaignDetails(campaign);
    getRaceGames(campaign.id, 1);
  };

  const getRaceGames = async (campaignId, page) => {
    var response = await api.get("/race/games/" + campaignId + "/" + page);
    if (response.status === 200) {
      setCampaignGames([...campaignGames, ...response.data.games]);
      setGameLoaded(campaignGames.length + response.data.games.length);
      setPagination(response.data.paginationData);
      setTotalGame(response.data.gameCount);
    }
  };

  const onPageChange = (page) => {
    getRaceGames(campaignDetails.id, page);
  };

  const RemainintTime = ({ timestamp }) => {
    const formatTimeStamp = (timestamp) => {
      var ret = "";
      ret += parseInt(timestamp / 3600) + " : ";
      ret +=
        parseInt((timestamp % 3600) / 60)
          .toString()
          .padStart(2, "0") + " : ";
      ret += parseInt(timestamp % 60)
        .toString()
        .padStart(2, "0");
      return ret;
    };

    var latestTimeStamp = timestamp;

    const [formatted, setFormatted] = useState(formatTimeStamp(timestamp));

    useEffect(() => {
      var interval = window.setInterval(() => {
        latestTimeStamp = latestTimeStamp - 1;
        if (latestTimeStamp <= 0) {
          window.clearInterval(interval);
          return 0;
        }
        setFormatted(formatTimeStamp(latestTimeStamp));
      }, 1000);
      return () => {
        window.clearInterval(interval);
      };
    }, [timestamp]);
    return <>{formatted}</>;
  };

  const calculateGridColumnsForGameImage = (gamesCount) => {
    if (!gamesCount) {
      return "repeat(1, 1fr)";
    }
    if (gamesCount > 3) {
      gamesCount = 3;
    }
    return "repeat(" + gamesCount + ", 1fr)";
  };

  const [racePage, setRacePage] = useState(null);
  const getRacePage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "races",
    });
    if (response.status === 200) {
      setRacePage(response.data);
    }
  };
  useEffect(() => {
    getRacePage();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {__("Casino Mounte Olympus | Slot Tournaments with HUGE prizes!")}
        </title>
        <meta name="keywords" content={require("elements/Keywords").default} />
        <meta
          name="description"
          content={__(
            "Online crypto casino slot tournaments where players win even bigger by crypto wagering against other players and win huge cash prizes."
          )}
        />
        <link
          rel="canonical"
          href={"https://casinomonteolympus.com" + window.location.pathname}
        />
        <meta
          property="og:title"
          content={__(
            "Casino Mounte Olympus | Slot Tournaments with HUGE prizes!"
          )}
        />
        <meta
          property="og:description"
          content={__(
            "Online crypto casino slot tournaments where players win even bigger by crypto wagering against other players and win huge cash prizes."
          )}
        />
        <meta property="twitter:image" content={racePage?.page?.banner} />
        <meta
          property="twitter:title"
          content={__(
            "Casino Mounte Olympus | Slot Tournaments with HUGE prizes!"
          )}
        />
        <meta
          property="twitter:description"
          content={__(
            "Online crypto casino slot tournaments where players win even bigger by crypto wagering against other players and win huge cash prizes."
          )}
        />
        <meta property="og:image" content={racePage?.page?.banner} />
      </Helmet>

      <div className="races_page">
        <section className="header_banner_area">
          <div className="single_banner_area">
            <img
              alt={__("Tournaments")}
              className="banner_bg desktop_banner"
              src={racePage?.page?.banner}
            />

            <img
              alt={__("Tournaments")}
              className="banner_bg tab_banner"
              src={racePage?.page?.banner_tab}
            />

            <img
              alt={__("Tournaments")}
              className="banner_bg mobile_banner"
              src={racePage?.page?.banner_mobile}
            />
          </div>
        </section>

        <div className="container">
          <div className="col-md-8 offset-md-2">
            <div className="winner_toggle margin_auto">
              <div
                onClick={() => {
                  setRaceTab("In Progress");
                }}
                className={
                  raceTab === "In Progress" ? "toggle_btn active" : "toggle_btn"
                }
              >
                {__("In Progress")}
              </div>
              <div
                onClick={() => {
                  setRaceTab("Ended");
                }}
                className={
                  raceTab === "Ended" ? "toggle_btn active" : "toggle_btn"
                }
              >
                {__("Ended")}
              </div>
            </div>
            <br />
            <div className="race_list">
              {races.length ? (
                <>
                  {races.map((item, index) => (
                    <li>
                      <div className="race_schedule dark_white text-center d-none d-md-block">
                        {moment(item.expiry_date, "YYYY-MM-DD HH:mm:ss").format(
                          "MMMM Do YYYY, hh:mm:ss A"
                        )}
                      </div>
                      <br />
                      <div className="race_dot d-none d-md-block"></div>
                      <div className="race_border d-none d-md-block"></div>
                      <div className="race_content">
                        {item.race_banner ? (
                          <div className="race_banner">
                            <img
                              alt={item.lang?.title}
                              src={item.race_banner}
                              className="race_banner_image"
                            />
                          </div>
                        ) : (
                          <div
                            className="race_banner"
                            style={{
                              gridTemplateColumns:
                                calculateGridColumnsForGameImage(
                                  item.games?.length ?? 0
                                ),
                            }}
                          >
                            {item.games.map((game, key) => {
                              if (key > 2) {
                                return null;
                              }
                              return (
                                <img
                                  alt={game.game_name}
                                  src={game.game_snapshot_url}
                                  className="race_banner_image"
                                />
                              );
                            })}
                          </div>
                        )}
                        <div className="race_details">
                          {item.ending_in ? (
                            <>
                              <h2 className="title">{item.lang?.title}</h2>
                              <div className="paragraph uppercase text-center no_margin">
                                <strong>
                                  {item.lang?.campaign_information}
                                </strong>
                              </div>
                              <div className="paragraph uppercase text-center no_margin">
                                {__("ENDING IN")}
                              </div>
                              <div className="time text-center">
                                <RemainintTime timestamp={item.ending_in} />
                              </div>
                            </>
                          ) : (
                            <>
                              <h2 className="title">{item.lang?.title}</h2>
                              <div className="paragraph uppercase text-center no_margin">
                                <strong>
                                  {item.lang?.campaign_information}
                                </strong>
                              </div>
                              <div className="paragraph uppercase text-center no_margin">
                                {__("THE RACE HAS")}
                              </div>
                              <div className="time text-center">
                                {__("FINISHED")}
                              </div>
                            </>
                          )}
                          <div className="bonuses">
                            {/*<div className="single_item">
                              <div className="paragraph uppercase text-center no_margin">
                                SPINS
                              </div>
                              <div className="bonus_value">60</div>
                            </div>*/}
                            <div className="single_item">
                              <div className="paragraph uppercase text-center no_margin">
                                {__("MIN BET")}
                              </div>
                              <div className="bonus_value min_bet">
                                ${item.minimum_wager}
                              </div>
                            </div>
                            {/*<div className="single_item">
                              <div className="paragraph uppercase text-center no_margin">
                                DURATION
                              </div>
                              <div className="bonus_value">3 days</div>
                            </div>*/}
                          </div>
                          <div className="view_leadboard">
                            <button
                              onClick={() => {
                                openDetails(item);
                              }}
                              className="common_btn_v1 fit_width"
                            >
                              {__("Race Rules")}
                            </button>
                            &nbsp; &nbsp;
                            <button
                              onClick={() => {
                                openLeaderBoard(item.id);
                              }}
                              className="common_btn_v1 fit_width deep"
                            >
                              {__("Leaderboard")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </>
              ) : (
                <>
                  <NoRecordsFound
                    content={
                      raceTab === "In Progress" ? (
                        <h4>
                          {__(
                            "Currently there are no active races but no worries - soon a new race will start!"
                          )}
                        </h4>
                      ) : (
                        <h4>
                          {__(
                            "There are no records found for past races. But no worries - soon a new race will start!"
                          )}
                        </h4>
                      )
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={showDetails}>
        <Modal.Body className={"login_modal " + props.theme}>
          <div
            onClick={() => {
              setShowDetails(false);
            }}
            className="modal_close"
          >
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="#666666"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 20L19.3848 1.61522"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M1 2L19.3848 20.3848"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>

          <div
            className="race_rules_desc"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(campaignDetails?.lang?.description),
            }}
          ></div>
          <div>
            <h4 className="modal_headline">
              {__("Games supported in the race")}
            </h4>
            <div className="details_games game_list">
              {campaignGames.map((game, index) => (
                <GameItem game={game} />
              ))}
            </div>
            {pagination && (
              <>
                <LoadMore data={pagination} onPageChange={onPageChange} />

                <div className="gameLoadingProgress">
                  <div className="gameLoadingInfo">
                    {__("You've viewed")} {gameLoaded} {__("of")} {totalGame}{" "}
                    {__("games")}
                  </div>
                  <ProgressBar
                    variant="success"
                    now={(gameLoaded / totalGame) * 100}
                  />
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={showLeaderBoard}>
        <Modal.Body className={"login_modal " + props.theme}>
          <div
            onClick={() => {
              setShowLeaderBoard(false);
            }}
            className="modal_close"
          >
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="#666666"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 20L19.3848 1.61522"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M1 2L19.3848 20.3848"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div className="modal_headline">{__("Leaderboard")}</div>

          <div className="leaderboard_table">
            <Table>
              <thead>
                <tr>
                  <td>#</td>
                  <td>{__("Player")}</td>
                  <td>{__("Wagered")}</td>
                  <td>{__("Cash Reward")}</td>
                  <td>{__("Bonus")}</td>
                </tr>
              </thead>

              <tbody>
                {leaderBoard.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.player_name}</td>
                    <td>${item.wagered}</td>
                    <td>${item.cash_prize}</td>
                    <td>${item.bonus}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
