import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "services/api";
import VipLevelText from "elements/VipLevelText";
import InputBox from "elements/InputBox";
import SelectBox from "elements/SelectBox";
import { Badge, ProgressBar } from "react-bootstrap";
import UserLeftArea from "elements/dashboard_elements/UserLeftArea";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../PageHeadline";

export default function ProfileDetails(props) {
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;
  const accountProgress = props.accountProgress;
  const getAccountProgress = props.getAccountProgress;
  const setSpinner = props.setSpinner;

  // const __ = (text) => text;

  const genders = [
    { label: __("Gender"), value: null },
    { label: __("Male"), value: "Male" },
    { label: __("Female"), value: "Female" },
    { label: __("Other"), value: "Other" },
  ];

  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const currencies = [
    { label: __("Currency"), value: null },
    { label: "USD", value: "USD" },
  ];
  const [phoneCodes, setPhoneCodes] = useState([]);

  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200) {
      setCountries(
        response.data.map((val) => {
          return { label: val.nicename, value: val.code };
        })
      );
      setPhoneCodes(
        response.data.map((val) => {
          return {
            label: "+" + val.phonecode + " (" + val.code + ")",
            value: "+" + val.phonecode,
          };
        })
      );
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      setLanguages(
        response.data.map((val) => {
          return { label: val.language, value: val.code };
        })
      );
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const getProfile = async () => {
    setSpinner(true);
    var response = await api.get("/profile");
    setSpinner(false);
    if (response.status === 200) {
      setForm(response.data);
      setIsDataFetched(true);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const [editMode, setEditMode] = useState(false);

  const submitProfile = async (ev) => {
    ev.preventDefault();
    setSpinner(true);
    var response = await api.post("/profile/save", {
      oldPassword: form.oldPassword,
      password: form.password,
      repassword: form.repassword,
    });
    setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      setForm({
        ...form,
        oldPassword: "",
        password: "",
        repassword: "",
      });
      setIsDataFetched(true);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    dateOfBirth: "",
    gender: "",
    currency: "",
    language: "",
    phoneCode: "",
    phone: "",
  });

  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getAllData = async (force) => {
    if (!isDataFetched || force) {
      setSpinner(true);
      await getCountries();
      await getLanguages();
      await getProfile();
      // setSpinner(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <PageHeadline page_name="Account Details" />

      <div className="credentials">
        <div className="left_side">
          <div className="id_no">
            {__("ID Casino Number :")}
            <span className="id"> bc2253575423</span>
          </div>
          <div className="edit_btn">
            <Link to="#" className="common_btn_v1 fit_width">
              {__("Edit Account")}
            </Link>
          </div>
          <></>
        </div>
        <div className="left_side">
          <div className="single_item">
            <span className="item_title">{__("Anonymous")}</span>
            
            <label className="switch">
              <input
                // value={settingsData.add_edit}
                name="add_edit"
                type="checkbox"
                className="singleCheckbox"
                // onChange={settingChange}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="single_item">
          <span className="item_title">{__("2FA")}</span>
            <label className="switch">
              <input
                // value={settingsData.add_edit}
                name="add_edit"
                type="checkbox"
                className="singleCheckbox"
                // onChange={settingChange}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div className="page_content_area">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6">
            <div className="input_item">
              <div className="name">{__("Name")}</div>
              <input
                className="form-control name_value"
                value={form.firstName + " " + form.lastName}
                type="text"
                readOnly={true}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6">
            <div className="input_item">
              <div className="name">{__("Email")}</div>
              <input
                className="form-control name_value"
                value={form.email}
                type="email"
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-sm-4 col-md-6 col-lg-4">
            <div className="input_item">
              <div className="name">{__("Birthday")}</div>
              <input
                className="form-control name_value select_box input_bg"
                value={form.dateOfBirth}
                type="text"
                readOnly={true}
              />
            </div>
          </div>
          <div className="col-6 col-sm-3 col-md-6 col-lg-3">
            <div className="input_item">
              <div className="name">{__("Phone Number")}</div>
              <input
                className="form-control name_value"
                value={form.phoneCode + " " + form.phone}
                type="phone"
                readOnly={true}
              />
            </div>
          </div>
          <div className="col-12 col-sm-5 col-md-12 col-lg-5">
            <div className="input_item">
              <div className="name">{__("Country")}</div>
              <input
                className="form-control name_value"
                value={
                  countries?.filter((item) => item.value === form.country)[0]
                    ?.label
                }
                type="text"
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6 col-sm-3 col-md-6 col-lg-3">
            <div className="input_item">
              <div className="name">{__("States")}</div>
              <input
                className="form-control name_value"
                value={form.state}
                type="text"
                readOnly={true}
              />
            </div>
          </div>
          <div className="col-6 col-sm-3 col-md-6 col-lg-3">
            <div className="input_item">
              <div className="name">{__("Zip Code")}</div>
              <input
                className="form-control name_value"
                value={form.zip}
                type="text"
                readOnly={true}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6  col-md-12 col-lg-6">
            <div className="input_item">
              <div className="name">{__("Address")}</div>
              <input
                className="form-control name_value"
                value={form.address + (form.city ? ", " + form.city : "")}
                type="text"
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-sm-4 col-md-6 col-lg-4">
            <div className="input_item">
              <div className="name">{__("Currency")}</div>
              <input
                className="form-control name_value select_box input_bg"
                value={form.currency}
                type="text"
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <h5>{__("Update Password")}</h5>

        <div className="row">
          <div className="col-12 col-sm-4">
            <div className="input_item">
              <div className="name">{__("Old Password")}</div>
              <InputBox
                className="form-control name_value"
                value={form.oldPassword}
                onChange={(value) => updateForm(value, "oldPassword")}
                type="password"
              />
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="input_item">
              <div className="name">{__("New Password")}</div>
              <InputBox
                className="form-control name_value"
                value={form.password}
                onChange={(value) => updateForm(value, "password")}
                type="password"
              />
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="input_item">
              <div className="name">{__("Re-type Password")}</div>
              <InputBox
                className="form-control name_value"
                value={form.repassword}
                onChange={(value) => updateForm(value, "repassword")}
                type="password"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <button
              onClick={submitProfile}
              className="common_btn_v1 fit_width margin_auto"
            >
              {__("Update Password")}
            </button>
          </div>
        </div>
      </div>

      {/* <UserLeftArea section="Account Details" form={form} {...props} /> */}
    </>
  );
}
