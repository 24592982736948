import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../PageHeadline";

export default function PromotionsBanner() {
  return (
    <div className="bottom_banner_v1">
      <div className="container">
        <PageHeadline page_name="Promotions" />
        <div className="row">
          <div className="col-lg-6">
            <div className="single_item blue">
              <div className="description">
                <div className="heading">{__("Tasty Tuesday")}</div>
                <div className="desc">{__("75% Bonus on Deposit")}</div>
                <div className="links">
                  <Link className="common_btn_v1 fit_width">
                    {__("Get the Deal!")}
                  </Link>
                </div>
              </div>
              <div className="icon">
                <img
                  src={
                    require("assets/images/v1/banner/promotion/1.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single_item blue">
              <div className="description">
                <div className="heading">
                  {__("1")}
                  <sup>{__("st")}</sup> {__("Deposit")}
                </div>
                <div className="desc">{__("100% up to 1 BTC!")}</div>
                <div className="links">
                  <Link className="common_btn_v1 fit_width">
                    {__("Get the Deal!")}
                  </Link>
                </div>
              </div>
              <div className="icon">
                <img
                  src={
                    require("assets/images/v1/banner/promotion/2.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single_item blue">
              <div className="description">
                <div className="heading">{__("Freespins Thursday")}</div>
                <div className="desc">{__("Deposit each Thursday ")}</div>
                <div className="links">
                  <Link className="common_btn_v1 fit_width">
                    {__("Get the Deal!")}
                  </Link>
                </div>
              </div>
              <div className="icon">
                <img
                  src={
                    require("assets/images/v1/banner/promotion/3.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single_item blue">
              <div className="description">
                <div className="heading">
                  {__("2")}
                  <sup>{__("nd")}</sup> {__("Deposit")}
                </div>
                <div className="desc">{__("50% up to 1.2 BTC!")}</div>
                <div className="links">
                  <Link className="common_btn_v1 fit_width">
                    {__("Get the Deal!")}
                  </Link>
                </div>
              </div>
              <div className="icon">
                <img
                  src={
                    require("assets/images/v1/banner/promotion/4.svg")
                      .default
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
