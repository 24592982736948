import React, { useState, useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Translator, { __ } from "services/Translator";
import VipBanner from "../elements/vip/VipBanner";
import VipRewards from "../elements/vip/VipRewards";
import VipWheel from "../elements/vip/VipWheel";
import VipRakeback from "../elements/vip/VipRakeback";

export default function Vip(props) {
  return (
    <div className="landing">
      <VipBanner {...props} />
      <VipRewards {...props} />
      {/* <VipWheel {...props} /> */}
      <VipRakeback {...props} />
    </div>
  );
}
