import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";

export default function PromotionFreespins() {
  return (
    <div className="container">
      <div className="promotion_cashback freespins">
        <div className="row">
          <div className="col-lg-6">
            <div className="single_cashback freespins">
              <div className="cashback_icon">
                <img
                  src={
                    require("assets/images/v1/banner/promotion/6.svg").default
                  }
                />
              </div>
              <div className="cashback_description">
                <div className="heading">{__("Freespins Thursday")}</div>
                <div className="desc">
                  {__(
                    "Get ready to whirl into a world of fun with our Thrilling Thursday Free Spins! Every week, we're dishing out fantastic free spins, no deposit required! Amp up the excitement and make your Thursdays truly unforgettable. The party never stops at Casino Monte Olympus, so spin your way to fabulous wins!"
                  )}
                </div>
                {/* <div className="title">
                  {__("Depends on your 1st Deposit each Thursday")}
                </div> */}
                {/* <div className="desc">
                  {__(
                    "You asked and we listened. Some of you have been requesting free spins promos for a while now, so we are very happy to oblige with our brand new Freespins Thursday promotion."
                  )}
                  <br />
                  <br />
                  {__(
                    "The number you get depends on your 1st deposit each Thursday:"
                  )}
                  <br />
                  <ul>
                    <li>{__("$10 - $49.99 gets you 5 Free Spins")}</li>
                    <li>{__("$50 - $99.99 gets you 10 Free Spins")}</li>
                    <li>{__("$100 - $199.99 gets you 20 Free Spins")}</li>
                    <li>{__("$200 - $499.99 gets you 50 Free Spins")}</li>
                    <li>
                      {__(
                        "$500 or more and a whopping 150 Free Spins will be yours"
                      )}
                    </li>
                  </ul>
                  <br />

                  {__(
                    " Any one of those spins could be a nice amount, winnings from Freespins need to be turned over 50 times before a withdrawal can be requested, other bonus terms apply which can be found at "
                  )}
                </div> */}
                <div className="links">
                  <Link className="common_btn_v1 fit_width">
                    {__("Get the Deal!")}
                  </Link>
                  <Link className="common_btn_v1 deep fit_width">
                    {__("Terms")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single_cashback freespins">
              <div className="cashback_icon">
                <img
                  src={
                    require("assets/images/v1/banner/promotion/7.svg").default
                  }
                />
              </div>
              <div className="cashback_description">
                <div className="heading">{__("Weekly Race")}</div>
                <div className="desc">
                  {__(
                    "Step into the exhilarating world of Casino Monte Olympus and join the pulse-pounding Weekly Race! Compete against fellow slot enthusiasts as you spin your way to victory and incredible prizes. Get ready for high-stakes fun and nail-biting action in our legendary showdown – become the ultimate slot champion and claim your well-deserved rewards!"
                  )}
                </div>
                {/* <div className="title">{__("Casino Monte Olympus $10k Raffle")}</div> */}
                {/* <div className="desc min_height_350">
                  {__(
                    "We are delighted to introduce another great way to pay back to our valuedplayers – the Casino Monte Olympus $10K Raffle! Every month, we will give away prizes to100 lucky players including a top prize of $2,500.All you nee"
                  )}
                  <br />
                  <br />

                  {__("Prize breakdowns are as follows:")}

                  <ul>
                    <li>{__("1st $2,500")}</li>
                    <li>{__("2nd $1,700")}</li>
                    <li>{__("3rd $1,200")}</li>
                    <li>{__("4th-10th $200")}</li>
                    <li>{__("11th – 20th $100")}</li>
                    <li>{__("21st – 40th $50")}</li>
                    <li>{__("41st – 100th $20")}</li>
                  </ul>
                </div> */}
                <div className="links">
                  <Link className="common_btn_v1 fit_width">
                    {__("Get the Deal!")}
                  </Link>
                  <Link className="common_btn_v1 deep fit_width">
                    {__("Terms")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="promotion_cashback dropswin">
        <div className="cashback_icon">
          <img
            src={require("assets/images/v1/banner/promotion/8.svg").default}
          />
        </div>
        <div className="cashback_description">
          <div className="heading small">{__("Pragmatic")}</div>
          <div className="title">{__("Drops & Wins")}</div>
          <div className="desc">
            {__(" Cash prizes all day long – It only takes a spin to win!")}
            <br />
            <br />

            {__(
              "Win your share of a massive €1,000,000 in cash prizes a month with Pragmatic Play's Drops and Wins 2022! there's a monthly prize pool of €500,000 for slots and another €500,000 for live games."
            )}
          </div>
          <div className="links">
            <Link className="common_btn_v1 fit_width">
              {__("Get the Deal!")}
            </Link>
            <Link className="common_btn_v1 deep fit_width">{__("Terms")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
