import React, { useState, useEffect } from "react";
import Translator, { __ } from "services/Translator";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import api from "services/api";

export default function OpenChat(props) {
  const history = useHistory();

  return (
    <div className=" open_chat">
      <Dropdown>
        <Dropdown.Toggle style={{ border: "none", background: "none" }}>
          <Link hrefLang={Translator.getSelectedLanguage().code} className="supportLink">
            <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.0514 13.539C22.1621 13.539 23.0461 13.845 23.7034 14.457C24.3721 15.0577 24.7064 15.8963 24.7064 16.973C24.7064 18.1743 24.3324 19.0753 23.5844 19.676C22.8477 20.2653 21.8334 20.5713 20.5414 20.594V22.6H19.0794V19.54H19.7594C20.8021 19.54 21.6294 19.3587 22.2414 18.996C22.8647 18.6333 23.1764 17.959 23.1764 16.973C23.1764 16.3043 22.9781 15.7773 22.5814 15.392C22.1961 15.0067 21.6691 14.814 21.0004 14.814C20.2977 14.814 19.7311 15.0123 19.3004 15.409C18.8811 15.8057 18.6714 16.327 18.6714 16.973H17.1754C17.1754 16.293 17.3341 15.6923 17.6514 15.171C17.9687 14.6497 18.4221 14.2473 19.0114 13.964C19.6007 13.6807 20.2807 13.539 21.0514 13.539ZM20.7624 24.181V26H18.8924V24.181H20.7624Z"
                fill="#666666"
              />
              <circle cx="20.5" cy="20.5" r="19.5" stroke="#666666" />
            </svg>
          </Link>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="modal_top_arrow"></div>
          <Dropdown.Item
            onClick={() => {
              props.setIsChatVisible(false);
              props.setSupportSidebarOpen(true);
            }}
          >
            {__("Support Chat")}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.setIsChatVisible(true);
              props.setSupportSidebarOpen(false);
            }}
          >
            {__("Players forum")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
